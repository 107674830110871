/* 
---------------------------------------------------------------
PopupTable
--------------------------------------------------------------- 
*/
.PopupTable {
  position:  absolute;
  top:  0;
  left:  0;
  background:  #fff;
  z-index:  1;
  border:  1px solid var(--color-light-grey);
  border-radius: 5px;
  max-height:  100%;
  max-width: 100%; /* make sure toolbar to the right is always visible */
  min-height: 47px; /* popup-table-header height + 2px border */
  display:  flex;
  flex-direction: column;
  overflow:  hidden;
  /*box-shadow: 1px 1px 1px 1px rgba(35,31,32,0.2);*/
}

.popup-table-move-handle {
  position: absolute;
  width: 100%;
  height: 10px;
  left: 0;
  top: 0;
  cursor: move;
}

.popup-table-header {
  display:  flex;
  flex-direction:  row;
  align-items: center;
  justify-content: space-between;
  font-family: "Rubik-Medium";
  padding: 5px 10px 5px 15px;
}

.popup-table-title {
  display:  flex;
  flex-direction:  row;
  align-items: flex-start;
  justify-content: flex-start;
}

.popup-table-title a {
  font-family: "Rubik-Regular";
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.popup-table-description {
  width: 0;
  min-width: 100%;
  padding: 0 15px 10px 15px;
  font-size: 0.9em;
}
.popup-table-body {
  padding: 0 10px 10px 10px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.popup-table-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
}

.popup-table-title .icon {
  margin:  0 10px 0 0;
}

.popup-table-toolbar {
  display:  flex;
  flex-direction:  row;
  align-items: center;
  margin-left: 20px;
}

.popup-table-toolbar .CustomCheckbox {
  margin-left: 10px;
}

.PopupTable .thin-text {
  font-family: "Rubik-Light";
  margin-left: 8px;
  color: var(--color-dark-grey);
}

.popup-table-toggle-button {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
  margin-left: 10px;
}
.popup-table-toggle-button::after {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 5px;
  top: 10px;
  display: block;
}
.popup-table-toggle-button::before {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 5px;
  top: 12px;
  display: block;
  transform: rotate(90deg);
}
.popup-table-toggle-button:hover {
  background: var(--color-black);
}
.popup-table-toggle-button:hover::before, 
.popup-table-toggle-button:hover::after {
  background: #fff;
}


.PopupTable .CustomTextareaCode {
  font-size: 0.75em;
}

.PopupTable .DataTableWrapper {
  width: initial !important;
  flex-grow: 0;
  display: flex;
  margin-top: 0;
  overflow-y: initial;
}

.PopupTable .DataTable {
  position: initial;
  overflow-y: initial;
}

.PopupTable .scroll-container {
  overflow-y: auto;
}

.PopupTable .DataTable .row {
  font-size:  0.9em;
}
.PopupTable .DataTable .row:hover {
  background-color:  #F7EEFF;
}

.PopupTable .DataTable td {
  padding:  5px;
  border-top: 1px solid var(--color-light-grey);
}
.PopupTable .DataTable th {
  padding:  0px 5px 5px 5px;
  padding-bottom:  5px;
  background-color:  #fff;
}
.PopupTable .DataTable .type-icon {
  width:  30px;
}
.PopupTable .empty-text {
  margin: 10px;
  font-size:  0.9em;
}

.PopupTable .lineage-table {
  overflow: hidden;
}


/* Make content fit to popup body on resize */
.PopupTable .CustomTextareaCode,
.PopupTable .CustomTextareaCode .DraftEditor-root ,
.PopupTable .CustomTextareaCode .DraftEditor-editorContainer,
.PopupTable .CustomTextareaCode .public-DraftEditor-content {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.PopupTable .CustomTextareaCode .public-DraftEditor-content {
  min-width: initial;
  margin-top: 0 !important;
  min-height: initial !important;
  max-height: initial;
}
.TechnicalDefinition {
  position: relative;
  overflow: hidden;
  height: 100%;
  min-height: 200px;
}

/* ------------*/

.selected-items-popup-header .icon {
  margin-right:  10px;
}
.selected-items-popup-title {
  font-size:  0.9em;
  color:  var(--color-dark-grey);
  margin:  0 25px 10px 0;
}

.selected-items-popup.one-item .selected-items-popup-title {
  display:  none;
  flex-direction: row;
}

.selected-items-popup-content {
  overflow-x:  hidden;
  overflow-y:  auto;
  display:  flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.selected-items-popup-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 2;
  justify-content: flex-start;
}

.selected-items-popup.one-item .selected-items-popup-content {

}
.selected-items-popup.one-item .selected-items-popup-section {
  overflow-x:  hidden;
  overflow-y:  auto;
}


.selected-items-popup-section:nth-last-child(n+2) {
  margin:  0 0 30px 0;
}


.selected-items-popup-section-title {
  display:  flex;
  flex-direction:  row;
  align-items: flex-start;
  font-family: "Rubik-Medium";
}
.selected-items-popup-section-title .link {
  font-family: "Rubik-Medium";
}

.selected-items-popup-section-title-text .icon {
  margin:  0 10px 0 0;
}




.selected-items-popup-section-description {
  margin: 10px 0 0 0;
  font-size:  0.9em;
}

.selected-items-popup-section-description.empty {
  margin: 0 0 0 0;
}

.selected-items-popup-section-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 2;
  overflow: hidden;
  justify-content: flex-start;
}
.selected-items-popup-section-footer {
  margin:  5px 0 0 0;  
}
.toggle-all-fields {
  font-size:  0.8em;
  cursor: pointer; 
  display:  inline-block;
  margin:  0 0 0 35px;
  color: var(--color-dark-grey);
}
.toggle-all-fields:hover {
  color:  var(--color-black); 
}
/*
.CustomTabs .tab-content {
  margin: 10px auto 0 auto;
  display:  flex;
  flex-direction: column;
  position: absolute;
  top: 0px; bottom: 0; left: 0; right: 0;
}

.CustomTabs .tab-content-wrapper {
  margin: 0px auto 0 auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
  overflow-y: auto;
  position:  relative;
  margin-bottom:  10px;
}*/

.selected-items-popup .DataTableWrapper {
  width: 100% !important;
}

.selected-items-popup .DataTable {
  position: initial;
}

.selected-items-popup .DataTable .row {
  font-size:  0.9em;
}
.selected-items-popup .DataTable .row:hover {
  background-color:  #F7EEFF;
}

.selected-items-popup .DataTable td {
  padding:  5px;
}
.selected-items-popup .DataTable th {
  padding:  0px 5px 5px 5px;
  padding-bottom:  5px;
  background-color:  #fff;
}
.selected-items-popup .DataTable .type-icon {
  width:  30px;
}
.selected-items-popup .empty-text {
  margin: 10px;
  font-size:  0.9em;
}


.selected-items-popup.hide {
transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
}

.selected-items-popup.hide .selected-items-popup-content
 {
  display:  none;
}
.selected-items-popup.hide .selected-items-popup-title {
  color:  var(--color-black);
  font-family: "Rubik-Medium";
}

.selected-items-popup.hide:hover {
  border:  1px solid var(--color-black);
  cursor: pointer;
  background:  var(--color-black);
}
.selected-items-popup.hide:hover .selected-items-popup-title {
  color:  #fff;
}


.selected-items-popup.hide.one-item .selected-items-popup-title {
  display:  flex;
  font-size: 1em;
}
.selected-items-popup.hide .toggle-popup {
  top:  12px;
  transform: rotate(45deg);
}
.selected-items-popup.hide .toggle-popup::after {
  top:  5px;
  left:  4px;
  width:  13px;
}
.selected-items-popup.hide .toggle-popup::before {
  top:  13px;
  left:  10px;
  width:  13px;
}

.selected-items-popup.hide:hover .toggle-popup {
  background: transparent;
}
.selected-items-popup.hide:hover .toggle-popup::after {
  background: #fff;
}
.selected-items-popup.hide:hover .toggle-popup::before {
  background: #fff;
}