/* 
---------------------------------------------------------------
Changelog
--------------------------------------------------------------- 
*/
.Changelog {
  position: absolute;
  top: 0px; bottom: 0; left: 0; right: 0;
  display:  flex;
  flex-direction: column;
}

.changelog-container {
  margin: 10px 0 0 0;
  overflow-y: auto;
}

.ChangelogItem {
  background-image: 
    linear-gradient(90deg, transparent, transparent 185px, #E0DAD455 185px, #E0DAD455 187px, transparent 187px);
  background-repeat: no-repeat;
  background-size: 100% calc(100%);
  background-position: 0 0px;
}
.Changelog.loading .ChangelogItem,
.ChangelogItem.single-item {
  background: none;
}

.ChangelogItem.first-item {
  background-image: 
    linear-gradient(90deg, transparent, transparent 185px, #E0DAD455 185px, #E0DAD455 187px, transparent 187px);
  background-repeat: no-repeat;
  background-size: 100% calc(100%);
  background-position: 0 30px;
}
.ChangelogItem.last-item {
  background-image: 
    linear-gradient(90deg, transparent, transparent 185px, #E0DAD455 185px, #E0DAD455 187px, transparent 187px);
  background-repeat: no-repeat;
  background-size: 100% 30px;
  background-position: 0 0px;
}

.loading-changelog {
  margin-top: 45px;
}

.loading-changelog-text {
}

.ChangelogItem {
  display: flex;
  padding: 0px 0 20px 0;
}

.changelog-item-header {
  margin: 9px 0 0 0;
}

.changelog-item-body {
  border: 1px solid transparent;
  margin: 20px 0 0 20px;
  padding: 20px 15px;
  position: relative;
  min-width: 250px;
}

.changelog-item-body.expandable {

  border: 1px solid var(--color-light-grey);
  background: #fff;
  border-radius: 5px;
  margin: 0px 0 0 20px;
  padding: 20px 15px;
  position: relative;
  min-width: 250px;
}

.changelog-item-body.expandable .changelog-item-body-header {
  cursor: pointer;
}

.changelog-item-body-header {
  display: flex;
  align-self: center;
  font-size: 0.9em;
  color: var(--color-dark-grey);
  white-space: pre-wrap;
  margin-left: 5px;
}
.changelog-item-body-header.strong {
  font-family: "Rubik-Medium";
  font-size: 1em;
  color: var(--color-black);
}

.changelog-item-body-header.second-row {
  cursor: default !important;
}
.changelog-item-body-header.second-row {
  cursor: default;
  margin-top: 20px;
}

.changelog-item-body table.field-list td {
  font-size: 1.0em;
  vertical-align: middle;
}

.changelog-item-body table td.type-fieldname  {

}

.changelog-item-body table td.type-pill {
  width: 90px;
  padding: 0 5px;
}
.changelog-item-body table td.type-pill .pill {
  margin: 0;
}
.changelog-item-body table td.type-icon {
  width: 25px;
  padding-right: 0 !important;
}
.changelog-item-body table td.type-icon .icon {
  margin: 0;
}

.changelog-item-body table .small-text {
  margin: 10px 0 0 0;
  font-size: 0.9em !important;
  color: var(--color-dark-grey);
}

.changelog-no-value {
  margin: 10px 0 0 0;
  font-size: 0.9em !important;
  color: var(--color-dark-grey);
  font-style: italic;
}


.changelog-item-body table .photo-img {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  vertical-align: middle;
  margin: 0 10px 0 0;
}
.changelog-item-body table .photo-img.placeholder {
  border-radius: 0%;

}

.changelog-item-body table {
  border-collapse: collapse;
  width: 100%;
}



.changelog-item-body th {
  text-align: left;
  font-size: 0.8em;
  font-family: "Rubik-Medium";
  padding: 5px 5px 5px 5px;
  border-bottom: 2px solid var(--color-light-grey);
}

.changelog-item-body td {
  vertical-align: top;
  word-break: break-all;
  text-align: left;
  padding: 5px 5px 5px 5px;
  border-bottom: 1px solid var(--color-light-grey);
}

.changelog-item-body td:nth-child(1) {
  font-size: 0.8em;
  padding-top: 8px;
  white-space: nowrap;
  font-family: "Rubik-Medium";
}
.changelog-item-body tr:nth-last-child(1) td {
  border-bottom: none !important;
}

.changelog-item-body td .with-icon {
  display: flex;
  align-items: flex-start;
}

.changelog-item-body td .icon {
  margin: 0 10px 0 0;
}

.changelog-item-body td .secret {
  font-style: italic;
  color: var(--color-dark-grey);
  font-size: 0.9em;
  white-space: nowrap;
}

.ChangelogItem.hidden .changelog-item-body table {
  margin: 5px 0 0 0;
}
.ChangelogItem.hidden .changelog-item-body th {
  display: none;
}
.ChangelogItem.hidden .changelog-item-body td:nth-child(n + 2) {
  display: none;
}
.ChangelogItem.hidden .changelog-item-body table.field-list td {
  display: table-cell;
}
.ChangelogItem.hidden .changelog-item-body tr:nth-last-child(1) td {
  padding-bottom: 0;
}
.ChangelogItem.hidden .changelog-item-body td {
  border-bottom: none;
  padding-top: 2px;
  padding-bottom: 2px;
}

.ChangelogItem .changelog-item-body .toggle-item {
  display: block;
  position: absolute;
  top:  10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
}
.ChangelogItem .changelog-item-body .toggle-item::after {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 5px;
  top: 10px;
  display: block;
}
.ChangelogItem .changelog-item-body .toggle-item::before {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 5px;
  top: 12px;
  display: block;
  transform: rotate(90deg);
}

.ChangelogItem .changelog-item-body .toggle-item:hover {
  background: var(--color-black);
}

.ChangelogItem .changelog-item-body .toggle-item:hover::before, 
.ChangelogItem .changelog-item-body .toggle-item:hover::after {
  background: #fff;
}

.ChangelogItem.hidden .changelog-item-body  {
  background: none;
  border: 1px solid transparent;
  transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
}

.ChangelogItem.hidden .changelog-item-body.expandable:hover {
  cursor: pointer;
  background:  #fff;
}

.ChangelogItem.hidden .changelog-item-body .toggle-item {
  display: none;
  top:  12px;
  transform: rotate(45deg);
}
.ChangelogItem.hidden .changelog-item-body .toggle-item::after {
  top:  5px;
  left:  4px;
  width:  13px;
}
.ChangelogItem.hidden .changelog-item-body .toggle-item::before {
  top:  13px;
  left:  10px;
  width:  13px;
}

.ChangelogItem.hidden .changelog-item-body.expandable:hover .toggle-item {
  background: transparent;
}
.ChangelogItem.hidden .changelog-item-body.expandable:hover .toggle-item::after {
  background: #fff;
}
.ChangelogItem.hidden .changelog-item-body.expandable:hover .toggle-item::before {
  background: #fff;
}
