/* 
---------------------------------------------------------------
Modal Dialog 
--------------------------------------------------------------- 
*/

.filter-blur {
  filter: blur(2px);
}

.modal-underlay { 
  display:  flex;
  justify-content: center;
  align-items: center;
  cursor: default !important;
  z-index: 20 !important;
  background: rgba(0,0,0,0.1) !important;
}

.modal-container {
  background: #fff;
  border-radius: 5px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  max-height: 80vh;
  max-width: 80vw;
  
  display:  flex;
  flex-direction:  column;
}

.modal-container-header {
  padding: 15px 50px 20px 20px;
  font-family: 'Rubik-Medium';
  /*background: var(--color-very-light-grey);*/
  border-radius: 5px 5px 0px 0px;
  position: relative;
  /*border-bottom: 1px solid var(--color-light-grey);*/
}

.modal-container-body {
  padding: 0 20px 0 20px;
  flex: 1 1 auto;
  overflow-y: auto;
}

.modal-container-body-code {
  white-space: pre-wrap !important;

}
 
.modal-container-body-text {
  max-width: 500px;
  white-space: pre-wrap !important;
}
.modal-container-body-text.wide {
  max-width: 1000px;
  white-space: pre-wrap !important;
}

.modal-container-footer {
  padding: 15px 20px 15px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal-container-footer-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.modal-container-footer-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-container-footer-right .footer-item:nth-child(n+1) {
  margin-left: 5px;
}
.modal-container-footer-left .footer-item:nth-child(n+1) {
  margin-right: 5px;
}

.modal-container-body ul {
  margin: 15px 0 0 0;
}
.modal-container-body li {
  list-style-type: none;
  margin-top: 0.5vw;
}

.modal-container .toggle-popup {
  right: 13px;
}
