/*
---------------------------------------------------------------
COLORS:

Very light grey: FAF9F7;
Light grey:   F5F2EF
Medium grey:  E0DAD4
Dark grey:    B8ADAB
Black:        222020
Yellow:       FBE9B7
Pink          FFD0FA
Purple:       E4C3FF
Blue:         B6CFFF
Red:          FFBEAB
Medium Red:   FF3F07
Medium Green: 2BCA93
---------------------------------------------------------------
*/
:root {
  --color-very-light-grey: #FAF9F7;
  --color-light-grey: #F5F2EF;
  --color-medium-grey: #E0DAD4;
  --color-dark-grey: #B8ADAB;
  --color-black: #222020;
  --color-yellow: #FBE9B7;
  --color-pink: #FFD0FA;
  --color-purple: #E4C3FF;
  --color-blue: #B6CFFF;
  --color-red: #FFBEAB;
  --color-light-red: #FF3F0799;
  --color-intense-red: #FF3F07;
  --color-intense-orange: #E8AB00;
  --color-light-green: #2BCA9399;
  --color-intense-green: #2BCA93;
  --color-light-blue: #74A2F977;
  --color-intense-blue: #74A2F9;
  --color-intense-yellow: #E7B835;
}



@font-face {
    font-family: 'Rubik-Light';
    font-style: normal;
    src: local('Rubik-Light'), local('Rubik Light'),
    	url(./assets/fonts/Rubik-Light.ttf) format('truetype')
}
@font-face {
    font-family: 'Rubik-Regular';
    font-style: normal;
    src: local('Rubik-Regular'), local('Rubik Regular'),
      url(./assets/fonts/Rubik-Regular.ttf) format('truetype')
}
@font-face {
    font-family: 'Rubik-Medium';
    font-style: normal;
    src: local('Rubik-Medium'), local('Rubik Medium'),
      url(./assets/fonts/Rubik-Medium.ttf) format('truetype')
}
@font-face {
    font-family: 'Rubik-Bold';
    font-style: normal;
    src: local('Rubik-Bold'), local('Rubik Bold'),
      url(./assets/fonts/Rubik-Bold.ttf) format('truetype')
}
@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    src: 
      url(./assets/fonts/JetBrainsMonoNL-Regular.ttf) format('truetype')
}
@font-face {
    font-family: 'JetBrainsMono-Medium';
    font-style: normal;
    src: 
      url(./assets/fonts/JetBrainsMonoNL-Medium.ttf) format('truetype')
}

body, a, h1, h2, h3, h4, p, ol, ul, li, th, td, tr,
textarea,
input[type=text], 
input[type=email], 
input[type=password], 
input[type=submit], 
input[type=button], 
button {
	font-family: 'Rubik-Light';
  font-weight: normal;
  font-size: 1em;
	margin: 0;
	padding: 0;  
  /*-webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
	/*-webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: none !important;*/
  color: var(--color-black);
  /* Prevent browsers to do custom styling on e.g. buttons */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Remove blue background on Chrome autofill elements */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset !important;
}

textarea,
input[type="text"],
input[type="password"],
input[type="email"],
input[type="button"],
input[type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border-radius: 0;
  filter: none;
}

textarea,
input[type="text"]:required,
input[type="password"]:required,
input[type="email"]:required { 
  box-shadow: none; 
}

:focus {
  outline-color: transparent;
  outline-style: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--color-dark-grey);
  opacity: 1; /* Firefox */
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--color-dark-grey);
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--color-dark-grey);
}

input[type=submit] {
	cursor: pointer;
}

* {
  box-sizing: border-box;
}

body {
    height: 100%;
}

a {
  outline: 0;
  text-decoration: none;
}
h1 {
	font-family: 'Rubik-Medium';
	font-size: 1.4em;
	text-align: left;
	font-weight: normal;
  margin: 0vw 0 0.5vw 0;
  word-break: break-all;
}

h1 .light-text {
  font-family: 'Rubik-Light';
  font-size: 1.0em;
}
h2 {

	font-size: 1.4em;
	text-align: left;
}
h3 {
  margin: 15px 0 5px 0;
  font-size: 0.75em;
  font-family: 'Rubik-Regular';
  text-align: left;
  color:  var(--color-dark-grey);
}
h4 {
  margin: 15px 0 10px 0;
  padding: 0 0 5px 0;
  font-size: 0.75em;
  font-family: 'Rubik-Regular';
  text-align: left;
  color: var(--color-dark-grey);
  border-bottom: 1px solid var(--color-medium-grey);
}

strong, .strong {
	font-family: 'Rubik-Medium';
}
p {
	margin: 0 auto 10px auto;
	font-size: 1em;
	overflow: hidden;
}

.no-result-text {
  font-style: italic;
  color: var(--color-dark-grey);
}

.link:hover {
  color: var(--color-purple);
}

.App-wrapper {
  position: relative;
  overflow-x: hidden;
  width: 100%;
}
.App {
  display: flex;
  flex-direction: row;
  transition: filter 0.2s ease-out;
}

/* 
---------------------------------------------------------------
Login 
--------------------------------------------------------------- 
*/
.fullscreen {
  display: flex;
  width: 100%;
  background: #fff;
}

.fullscreen-content {
  text-align: center;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 15vh;
  padding-bottom: 5vh;
}

.fullscreen-text {
  text-align: center;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
}

.fullscreen-loading-text {
}

.fullscreen-loading-logo {
  width: 15vw;
  height: 15vw;
  max-width: 300px;
  min-width: 200px;
  max-height: 300px;
  min-height: 200px;
  margin: 0 auto 5vh auto;
}


.fullscreen-logo {
  background-image: url('./assets/img/logo.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 15vw;
  height: 15vw;
  max-width: 300px;
  min-width: 200px;
  max-height: 300px;
  min-height: 200px;
  margin: 0 auto 5vh auto;
}
.fullscreen .error-msg {
  margin-top: 2vw;
  color: var(--color-intense-red);
  padding: 0 4vw;
  max-width: 800px;

}
.fullscreen .error-title {
  font-size: 1.2em;
  font-family: "Rubik-Medium";
  white-space: pre-wrap;
}
.fullscreen .error-details {
  margin-top: 10px;
  font-size: 1.0em;
  text-align: left;
  word-break: break-word;
  white-space: pre-wrap;
}

.fullscreen .CustomTabs {
  display:  block !important;
  flex-grow: 0;
}
.fullscreen .tab-content {
  position: initial !important;
  
  margin: 0 !important;
  margin-top: 40px !important;
}

.fullscreen .tab-list {

  display:  flex;
  flex-direction: row;
}

.fullscreen .tab-list-item {
  flex-grow: 1;
  margin: 0 !important;
}


.forgot-password-link {
  margin: 15px 0 15px 0;
  display: inline-flex;
  font-size: 0.9em;
  color: var(--color-dark-grey);
}
.forgot-password-link:hover {
  color: var(--color-black);
}

/* 
---------------------------------------------------------------
ChangePassword 
--------------------------------------------------------------- 
*/

.ChangePassword .close-button {
  position: absolute;
  top: 0.6vw;
  right: 0.6vw;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ChangePassword .close-button-icon {
  width: 1.6vw;
  height: 1.6vw;
  border-radius: 100%;
  transform: rotate(45deg);
  cursor: pointer;
}
.ChangePassword .close-button-icon::after {
  width: 1.2vw;
  height: 0.1vw;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 0.2vw;
  top: 0.65vw;
  display: block;
}
.ChangePassword .close-button-icon::before {
  width: 1.2vw;
  height: 0.1vw;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 0.2vw;
  top: 0.75vw;
  display: block;
  transform: rotate(90deg);
}

.ChangePassword .close-button-label {
  display: block;
  font-size: 0.8em;
  margin-right: 0.5vw;
}

.ChangePassword .close-button:hover .close-button-icon {
  background: var(--color-black);
}
.ChangePassword .close-button:hover .close-button-icon::after,
.ChangePassword .close-button:hover .close-button-icon::before {
  background: #fff;
}

.ChangePassword .close-button:hover .close-button-label {
  display: block;
}

/* 
---------------------------------------------------------------
Navigation 
--------------------------------------------------------------- 
*/
.navbar {
  display: flex;
  /*width: 250px;
  margin-left: -250px;*/
  width: 75px;
  min-width: 75px;
  max-width: 250px;
  flex-direction: column; 
  
  min-height: 100vh;
  background: var(--color-black);
  color: #fff;
  position: relative;
  /*transition: margin-left 0.2s ease-out;*/
  transition: width 0.2s ease-out, min-width 0.2s ease-out;

}

.navbar-header {
  height: 60px;
  position: static;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 20px;
  margin: 0 0 20px 0;
}

.navbar-header a {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #fff;
}

.navbar.show .navbar-header .logo-text {
  opacity: 1;
  
}

.navbar-header .logo-text {
  margin: 0 0 0 10px;
  font-size: 1.2em;
  letter-spacing: 0.05em;
  opacity: 0;
  transition: opacity 0.2s ease-in;
}

.navbar-header .icon {
  margin: 0 0 0 0;
}

.navbar-footer {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 5px 0 5px;
}

.navbar-content {
  padding: 0 5px 0 5px;
  flex-grow: 1;
}

.navbar.show {
  /*margin-left: 0vw;*/
  width: 250px;
  min-width: 250px;
}

.navbar-group {
  display: flex;
  flex-direction: column;
  margin: 10px 0 40px 0;
}

.navbar-group-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Rubik-Medium';
  font-size: 0.6em;
  height: 15px;
  letter-spacing: 0.1em;
  margin: 0 0 10px 10px;
  transition: font-size 0.2s ease-out, margin 0.2s ease-out;
}

.navbar.show .navbar-group-title {
  font-size: 0.75em;
  margin: 0 0 10px 20px;
}

.navbar .grey-text {
  color: var(--color-dark-grey);
}

.navbar .yellow-text {
  color: #FBE9B7; 
  background: linear-gradient(170deg, #FBE9B7 40%, #E4C3FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.navbar .pink-text {
  color: var(--color-pink); 
  background: linear-gradient(170deg, var(--color-pink) 30%, #E4C3FF 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-link.home.active .icon path {
  stroke: url(#gradient-home) !important;
}
.nav-link.datasets.active .icon path {
  stroke: url(#gradient-field-descriptions) !important;
}
.nav-link.field-descriptions.active .icon path {
  stroke: url(#gradient-field-descriptions) !important;
}
.nav-link.business-terms.active .icon path {
  stroke: url(#gradient-field-descriptions) !important;
}
.nav-link.users.active .icon path {
  stroke: url(#gradient-users) !important;
}
.nav-link.tasks.active .icon path {
  stroke: url(#gradient-tasks) !important;
}
.nav-link.field-units.active .icon path {
  visibility: hidden !important;
}


.navbar-group .large {
  font-size: 2em;
}

.nav-link {
  font-size: 1.2em;
  padding: 0 0 0 20px;
  height: 50px;
  border-radius: 5px;
  color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  clear: both;
  text-decoration: none;
  transition: background-color 0.2s ease-out;
}

.nav-link .icon path.fill,
.nav-link.active .icon path {
  visibility: hidden !important;
}

.nav-link.active .icon path.fill {
  visibility: visible !important;
}

.nav-link.active .icon path {
  stroke: #E4C3FF !important;
}
.nav-link.active .icon path.fill {
  stroke: none !important;
  /*fill: #E4C3FF !important;*/
}

.nav-link.active, 
.nav-link.active:hover {
  color: #E4C3FF;
  background-color: rgba(255,255,255,0.1);
  border-radius: 5px !important;
}

.nav-link span {
  margin-left: 15px;
  width: 0;
  opacity: 0;
  flex-grow: 2;
  white-space: nowrap;
  transition: opacity 0.2s ease-out;
}

.navbar.show .nav-link span {
  opacity: 1;
  transition: opacity 0.2s ease-in;
}

.nav-link:hover {
  background-color: rgba(255,255,255,0.1);
}

.nav-link:active {
  transform: scale(0.95);
}

.navbar .navbar-toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75px;
  transition: transform 0.2s ease 0.2s;
  transform: scaleX(-1);
}
.navbar.show .navbar-toggle-button {
  transform: scaleX(1);
  width: 50px;
}

.navbar .navbar-toggle-button span {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  
}

.navbar .navbar-toggle-button span:hover {
  background: #fff;
}

.navbar .navbar-toggle-button span::after,
.navbar .navbar-toggle-button span::before {
  content: '';
  background: var(--color-dark-grey);
  position: absolute;
  border-radius: 2px;
  left: 10px;
  top: 10px;
}
.navbar .navbar-toggle-button span::after {
  width: 15px;
  height: 2px;
}
.navbar .navbar-toggle-button span::before {
  width: 2px;
  height: 15px;
}
.navbar .navbar-toggle-button span:hover::after,
.navbar .navbar-toggle-button span:hover::before {
  background: var(--color-black);
}

/* 
---------------------------------------------------------------
Main Content 
--------------------------------------------------------------- 
*/
.main-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.main-content-header {
  height: 50px;
  position: relative;
  border-bottom: 1px solid var(--color-medium-grey);
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  padding: 0 15px 0 15px;
}

.main-content-header.home {
  border-bottom: 1px solid transparent;
}

.main-content-header-wrap {
  position: static;
  background: var(--color-light-grey);
}
 
.main-content-header .logo {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8em;
}
.main-content-header .menu {
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: center;
  font-size: 0.9em;
}

.main-content-header .logo {
  letter-spacing: 0.3em;
  font-size: 1.3em;
  flex-grow: 2;
  text-align: center;
}

.main-content-header .menu .menu-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 15px;
}
.main-content-header .menu .menu-item:hover {
  
}

.main-content-header .menu .menu-item:hover .menu-content {
  display: block;
}

.main-content-header .menu .menu-button {
  text-decoration: none;
  cursor: pointer;
  height: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  white-space: nowrap;
}

.main-content-header .menu .menu-item.last {
  margin-right: 0px
}

.main-content-header .menu-item .menu-button:hover {
  background: transparent;
}
.main-content-header .menu .menu-button .user-name {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.main-content-header .menu .menu-button .user-name::after,
.main-content-header .menu .menu-item:hover .user-name::after {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  content: '';
  border-top: 5px solid var(--color-black);
  border-bottom: none;
  margin: 0px 0 3px 10px;
}

.main-content-header .menu .menu-button .user-name::after {
  border-top: 5px solid transparent;
}

.main-content-header .menu .menu-content {
  border: 1px solid #fff;
  border-top: 1px solid var(--color-medium-grey);
  position: absolute;
  z-index: 1000;
  right: 0;
  top: calc(100% + 0px);
  background: #fff;
  display: none;
  overflow: hidden;
  flex-direction: column;
  border-radius: 3px;
  box-shadow: 0px 3px 3px rgba(35,31,32,0.2);
}

.main-content-header .menu .menu-link {
  color: var(--color-black);
  display: block;
  text-decoration: none;
  padding: 0 20px 0 15px;
  white-space: nowrap;
  border-bottom: 1px solid #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 50px;
}

.main-content-header .menu .menu-link .icon {
  margin-right: 10px;
}

.main-content-header .menu .menu-link span {
  display: flex;
  flex-grow: 2;
}

.main-content-header .menu .menu-link:hover .icon path {
  stroke: rgb(255,255,255) !important;
}

.main-content-header .menu .menu-link:hover {
  background: var(--color-black);
  color: #fff;
  border-bottom: 1px solid transparent;
  border-radius: 3px;
}

.main-content-header .menu .about-icon {
  border: 1px solid var(--color-dark-grey);
  color: var(--color-dark-grey);
  border-radius: 50%;
  display: flex;
  width: 21px;
  height: 21px;
  font-size: 1em;
  font-family: 'Rubik-Medium';
  justify-content: center;
  align-items: center;
}

.main-content-header .menu-item:hover .about-icon {
  background: var(--color-black);
  color: #fff;
  border: 1px solid var(--color-black);
}

.main-content-header .about-logo {
  background-image: url('./assets/img/logo_small.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 100px;
  height: 100px;
  margin: 10px 20px 0 20px;
}

.main-content-header .about-version {
  color: var(--color-dark-grey);
  margin: 15px;
  text-align: center;
}

.main-content-header .about-copyright {
  color: var(--color-dark-grey);
  margin: 15px;
  text-align: center;
  font-size: 0.9em;
}

.main-content-header .about-copyright a {
  color: var(--color-dark-grey);
}

.main-content-header .about-copyright a:hover {
  color: var(--color-black);
}

.main-content-header .about-copyright a:hover {
  color: var(--color-black);
}

.user-profile {
  display:  flex;
  flex-direction: row;
  align-items: center;
}

.user-profile .Photo {
  margin: 10px;
}
.user-profile.slim .Photo {
  margin: 0 8px 0 0;
}
.user-profile-info {
  padding: 10px 20px 10px 10px;
}
.user-profile.slim .user-profile-info {
  padding: 8px;
}
.user-profile-name {
  font-family: 'Rubik-Medium';
  white-space: nowrap;
}
.user-profile-username {
  font-size: 0.9em;
  color: var(--color-dark-grey);
}
.main-content-header .horizontal {
  display: flex;
  flex-direction: row;
}
.main-content-header .horizontal .menu-link {
  flex-grow:  1;
}



.main-content {
  padding: 0;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  overflow-x: hidden;
  overflow-y: hidden;
  /*height: 50vh;*/
  background-color: var(--color-very-light-grey);
}

.main-content .narrow-margin {
  /*margin: 15px 15px;*/
  margin: 0;
}
.main-content .wide-margin {
  margin: 15px 30px 0px 30px;
  display: flex;

  width: 100%;
  flex-direction: column;
  align-items: stretch;
}

.main-content .columns {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.MainColumn {
  background: #fff;
  border-right: 1px solid var(--color-light-grey);
  min-width: 200px;
  max-width: calc(80vw - 250px);
  /* Handled in component to enable resizing functionality */
  /*flex-grow: 1;*/
  /*width: 30vw;*/
  transition: width 0.2s ease-out, max-width 0.2s ease-out, min-width 0.2s ease-out, padding 0.2s ease-out;
  position: relative;
  height: calc(100vh - 50px);
}
.main-content .columns .column {
  padding: 10px 20px 0px 20px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.MainColumn.column {
  padding: 20px 0px 25px 30px !important;
}

.MainColumn .main-column-resize-control {
  width: 3px;
  height: 100%;
  position: absolute;
  z-index: 1;
  right: 0;
  top: 0;
  cursor: ew-resize;
  border-right: 1px solid transparent;
}

.MainColumn .main-column-resize-control:hover,
.MainColumn .main-column-resize-control:active {
  border-right: 1px solid #22202033;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}
.MainColumn.hide .main-column-resize-control {
  display: none;
}

.MainColumn .button-back {
  position: absolute;
  z-index: 10;
  top: 7px;
  right: -10px;
  border: 0px solid var(--color-very-light-grey);
  border-radius: 50px;
  background:  none;
  height: 35px;
  padding: 5px 13px;
  margin: 0 20px 0px 0;
  text-transform: uppercase;
  font-size:  0.8em;
  cursor: pointer;
  display:  flex;
  flex-direction: row;
  align-items: center;
  font-family: "Rubik-Light";
  transition: background 0.1s ease-out, color 0.1s ease-out, border-color 0.1s ease-out, right 0.2s ease-out;
}
.MainColumn .button-back:hover {
  border: 0px solid var(--color-black);
  background:  var(--color-black);
  color:  #fff;
}
.MainColumn .button-back:active {
  transform:  scale(0.95);
}
.MainColumn .button-back .button-back-arrow {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 10px;
  height: 10px; 
  margin: 0 3px 0 0;
}

.MainColumn .button-back .button-back-arrow::after,
.MainColumn .button-back .button-back-arrow::before {
  content: '';
  background: var(--color-black);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
  transition: background 0.1s ease-out;
}
.MainColumn .button-back .button-back-arrow::after {
  width: 10px;
  height: 2px;
}
.MainColumn .button-back .button-back-arrow::before {
  width: 2px;
  height: 10px;
}
.MainColumn .button-back:hover .button-back-arrow::after,
.MainColumn .button-back:hover .button-back-arrow::before {
  background: #fff;
}
.MainColumn .button-back .button-back-label {
  overflow: hidden;
  text-align: left;
  transition: opacity 0.2s ease-out;
}
.MainColumn.hide .button-back .button-back-label {
  width: 0;
  opacity: 0;
}
.MainColumn.hide .button-back {
  width: 35px;
  right: -12.5px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.MainColumn.hide .button-back .button-back-arrow {
  margin:  2px 0 0 2px;
}


.MainColumn .button-toggle-main-column {
  position: absolute;
  width: 30px;
  height: 30px;
  /*top: calc(50% - 15px);*/
  bottom: 10px;
  right: 10px;
  transition: right 0.2s ease-out, transform 0.2s ease 0.2s;
  z-index: 10;
  transform: scaleX(1);
}

.MainColumn.hide .button-toggle-main-column {
  transform: scaleX(-1);
}

.MainColumn.hide .button-toggle-main-column {
  right: calc(50% - 15px);
}

.MainColumn .button-toggle-main-column span {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
  
}

.MainColumn .button-toggle-main-column:hover span {
  background: var(--color-black) !important;
}

.MainColumn .button-toggle-main-column span::after,
.MainColumn .button-toggle-main-column span::before {
  content: '';
  background: var(--color-medium-grey);
  position: absolute;
  border-radius: 2px;
  left: 10px;
  top: 10px;
}
.MainColumn .button-toggle-main-column span::after {
  width: 15px;
  height: 2px;
}
.MainColumn .button-toggle-main-column span::before {
  width: 2px;
  height: 15px;
}
.MainColumn .button-toggle-main-column:hover span::after,
.MainColumn .button-toggle-main-column:hover span::before {
  background: #fff;
}

.MainColumn.hide {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  padding-left: 24px !important;
  padding-right: 0px;
  padding-top: 20px;
  cursor: pointer;
  overflow: hidden;
}

.ObjectDetails .object-type,
.ObjectDetails .object-subtitle,
.ObjectDetails .object-secondary-title,
.ObjectDetails .object-description,
.ObjectDetails .object-content,
.ObjectDetails .object-details,
.ObjectDetails .object-details-toggle-button,
.ObjectDetails .object-container,
.MainColumn .main-toolbar {
  opacity: 1;
  transition: opacity 0.2s ease-out 0.2s, transform 0s ease 0.2s;
  visibility: visible;
  transform: scale(1);
  white-space: pre-wrap;
  word-break: break-word;
}

.ObjectDetails .object-type,
.ObjectDetails .object-subtitle,
.ObjectDetails .object-secondary-title,
.ObjectDetails .object-description,
.ObjectDetails .object-content,
.ObjectDetails .object-details,
.ObjectDetails .object-details-toggle-button,
.MainColumn .main-toolbar {
  margin-right: 20px !important; 
}

.ObjectDetails .object-container {

  overflow-y: auto; 
  flex: 1 1 auto;

}

.ObjectDetails .object-secondary-title {
  line-height: 1.5em;
}

.MainColumn.hide .ObjectDetails {
  overflow: initial;
}

.MainColumn.hide .object-title {
  transform: rotate(90deg);
  width: calc(100vh - 100px);
}
.MainColumn.hide .object-title h1 {
  font-size: 1em;
  margin: 0;
  text-align:  left !important;
}
.MainColumn.hide .object-type,
.MainColumn.hide .object-subtitle,
.MainColumn.hide .object-secondary-title,
.MainColumn.hide .object-description,
.MainColumn.hide .object-details,
.MainColumn.hide .object-details-toggle-button,
.MainColumn.hide .object-container,
.MainColumn.hide .object-content {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s ease-out, visibility 0s ease 0.2s, transform 0s ease 0s;
  max-height: 100px;
}

.MainColumn.hide .Photo {
  margin: 35px 0 5px -16px;
}
.MainColumn.hide .Photo,
.MainColumn.hide .Photo .photo-img,
.MainColumn.hide .Photo .photo-loader {
  width: 32px !important;
  height: 32px !important
}


.MainColumn.hide .main-toolbar {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s ease-out, visibility 0s ease 0.2s;
  white-space: nowrap;
  word-break: none;
}

.ObjectDetails {
  padding: 10px 0px 20px 0;
  background: #fff;
  border-radius: 5px;
  z-index:  1;
  overflow: hidden;
  
  /*position: relative;*/
  display: flex;
  flex-direction: column;
  
}

.ObjectDetails .object-type {
  white-space: nowrap;
  font-size: 0.75em;
  text-transform: uppercase;
  font-family: 'Rubik-Regular' !important;
  font-weight: normal;
  margin-bottom: 5px;
}

.ObjectDetails .object-title {
  transition: transform 0.2s ease-out, width 0.2s ease-out;
  transform-origin: center left;
  transform: rotate(0deg);
  width: 100%;
  padding-right: 20px !important; 
}
.ObjectDetails .object-secondary-title {
  margin: 0 0 5px 0;
}
.ObjectDetails .object-title h1 {
  transition: font-size 0.2s ease-out;
}

.ObjectDetails.center .object-subtitle {
  justify-content: center;
}

.ObjectDetails .object-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 15px 0;
}

.ObjectDetails .object-description {
  margin: 0 0 25px 0;
}

.ObjectDetails .object-details table {
  border-collapse: collapse;
}

.ObjectDetails .object-details td {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  white-space: pre-wrap;
  padding-bottom: 5px;
}
.ObjectDetails .object-details .object-details-title {
  font-size: 0.75em;
  padding-right: 20px;
  font-family: "Rubik-Regular";
  word-break: keep-all;
  white-space: nowrap;
  vertical-align: top;
  line-height: 1.5em;
  display: flex;
  flex-direction: column;
}

.ObjectDetails .object-details .object-details-title .second-row {
  font-family: "Rubik-Light";
}

.ObjectDetails .object-details .object-details-data {
  vertical-align: top;
}

.ObjectDetails .object-details .object-details-data a {
  display: inline-flex;
  color: var(--color-dark-grey);
}
.ObjectDetails .object-details .object-details-data a:hover {
  color: var(--color-black);
}
.ObjectDetails .object-details .object-details-data a .icon {
  margin: 0 5px 0 0;
}

.ObjectDetails .object-details-toggle-button {
  font-size:  0.75em;
  margin:  0px 0 20px 0;
  display: inline-flex;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  justify-content: flex-start;
  color: var(--color-dark-grey);
  font-family: "Rubik-Regular";
}

.ObjectDetails .object-details-toggle-button span {
  transform: rotate(135deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 7px;
  height: 7px; 
  margin: 0 3px 2px 5px;
  transition: transform 0.1s ease-out;
}
.ObjectDetails .object-details-toggle-button:hover {
  color: var(--color-black);
}
.ObjectDetails .object-details-toggle-button:hover span::after,
.ObjectDetails .object-details-toggle-button:hover span::before {
  background: var(--color-black);
}

.ObjectDetails .object-details-toggle-button.show span {
  transform: rotate(225deg);
}

.ObjectDetails .object-details-toggle-button span::after,
.ObjectDetails .object-details-toggle-button span::before {
  content: '';
  background: var(--color-dark-grey);
  border-radius: 2px;
  left: 0px;
  top: 0px;
  position: absolute;
}

.ObjectDetails .object-details-toggle-button span::after {
  width: 7px;
  height: 1px;
}
.ObjectDetails .object-details-toggle-button span::before {
  width: 1px;
  height: 7px;
}


.ObjectDetails .icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 15px 0 0;
  min-width: 1.0em;
}

.ObjectDetails .object-subtitle-icon .icon {
  margin: 0 5px 0 0;
}
.ObjectDetails .object-subtitle-icon:nth-last-child(2) .icon {
  margin: 0 15px 0 0;
}
.ObjectDetails .object-subtitle-text {
  font-size: 0.9em;
  flex-grow: 2;
  flex-direction: row;
  margin: 0 0 0 0;
  color: var(--color-dark-grey);
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;
}


.ObjectDetails.center .object-title h1 {
  text-align:  center;
}

.ObjectDetails.center .object-subtitle-text,
.ObjectDetails.center .object-secondary-title {
  text-align: center;
  justify-content: center;
}

.ObjectDetails .Photo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100% !important;
  margin: 10px 0 20px 0;
}

.ObjectDetails .Photo,
.ObjectDetails .Photo .photo-img,
.ObjectDetails .Photo .photo-loader {
  transition: width 0.2s ease-out, height 0.2s ease-out, margin 0.2s ease-out;
}

.ObjectDetails .object-content {
  margin: 0 0 25px 0;
}

.ObjectDetails .link {
  display: inline-flex;
  flex-direction: row;
}








.dark-background {
  background-color: var(--color-light-grey);
}

.display-linebreak {
  white-space: pre-wrap;
  display: flex;
  flex-direction: row;
  align-items:  center;
}

.code {
  font-family: "JetBrainsMono", monospace;
  font-size: 0.9em;
  line-height: 1.5em;
  white-space: pre-wrap;
}

/* 
---------------------------------------------------------------
SearchBar
--------------------------------------------------------------- 
*/

.SearchBar {
  position: relative;
  width: 500px;
  margin: 0 auto 0 auto;
}

.SearchBar.slim {
  position: relative;
  width: 300px;
  margin: 0 auto 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.SearchBar .input-container-wrapper {
  border: 1px solid var(--color-medium-grey) !important;
  background: var(--color-very-light-grey)  !important;
  border-radius: 7px !important;
}
.SearchBar .input {

  font-size: 1.4em;
  margin: 0 auto 0 auto;
  padding: 10px 10px 10px 60px;
  width: 100%;
  transition: transform 0.1s ease-in;
  background: url('./assets/img/icons/search.svg');
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 30px;
}

.SearchBar.slim {
  font-size: 0.9em;
}

.SearchBar.slim .input {

  font-size: 1.15em;
  margin: 0 auto 0 auto;
  padding: 10px 10px 10px 40px;
  width: 100%;
  transition: transform 0.1s ease-in;
  background: url('./assets/img/icons/search.svg');
  background-repeat: no-repeat;
  background-position: 10px center;
  background-size: 22px;
}
.SearchBar.invalid .input-container-wrapper,
.SearchBar.invalid .input-container-wrapper:focus-within,
.SearchBar.invalid .input-container-wrapper:hover {
  border: 1px solid var(--color-intense-red) !important;
}

.SearchBar .error-message {
  display: none;
  position: absolute;
  color: var(--color-light-red);
  font-size: 0.8em;
  right: 6px;
  top: calc(50% - 17px);

}

.SearchBar.invalid .error-message {
  display: flex;
}

.SearchBar .button-clear-search {
  display: none;
  position: absolute;
  padding: 10px 5px;
  top: calc(50% - 21px);
  right: 10px;
  cursor: pointer;
}

.SearchBar.slim .button-clear-search {
  top: calc(50% - 15px);
  right: 5px;
}

.SearchBar .button-clear-search.show {
  display: block;
}

.SearchBar .input-container-wrapper:focus-within,
.SearchBar .input-container-wrapper:hover {
  border: 1px solid var(--color-black) !important;
  background: #fff !important;
}

.SearchBar .button-clear-search:hover .button-clear-search-icon::after,
.SearchBar .button-clear-search:hover .button-clear-search-icon::before {
  background: var(--color-black);
}

.SearchBar .button-clear-search .button-clear-search-icon {
  transform: rotate(45deg);
  display: flex;
  align-items: center;
}

.SearchBar .button-clear-search .button-clear-search-icon::before {
  width: 20px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 0;
  top: 0vw;
  display: block;
}
.SearchBar .button-clear-search .button-clear-search-icon::after {
  width: 2px;
  height: 20px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: calc(-50%);
  top: 0;
  display: block;
}

.SearchBar.slim .button-clear-search .button-clear-search-icon::before {
  width: 12px;
}
.SearchBar.slim .button-clear-search .button-clear-search-icon::after {
  height: 12px;
}

.SearchBar.slim .search-result-dropdown {
  display: none;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 1px);
  right: 0;
  z-index: 20;
  background: #fff;
  max-height: 67vh;
  max-width: 67vw;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 3px;
  padding: 1px;
  box-shadow: 0px 3px 3px rgba(35,31,32,0.2);
}

.SearchBar.slim .search-result-dropdown-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--color-dark-grey);
  padding: 5px 15px 5px 15px;
  cursor: default;
}

.SearchBar.slim .search-result-dropdown-header .link {
  margin-left: 20px;
}

.SearchBar.slim .search-result-dropdown-content {
  flex-direction: column;
  height: 100%;
  max-width: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.SearchBar.slim .search-result-dropdown.show {
  display: flex;
}

.SearchResult .search-result {
  padding: 10px 15px 15px 15px;
  border-radius: 3px;
}

.SearchResult .search-result:hover,
.SearchResult .search-result-link:focus .search-result {
  background: #fff;
}

.SearchBar.slim .search-result:hover,
.SearchBar.slim .search-result-link:focus .search-result {
  background: #F7EEFF;
}

.SearchResult .search-result:active {
  transform: scaleX(0.99) scaleY(0.95);
}

.SearchResult .search-result-type {
  font-size: 0.65em;
  display: inline-block;
  padding: 0 0 5px 0;
  border-radius: 3px;
  font-family: "Rubik-Regular";
}

.SearchBar.slim .search-result-type {
  font-size: 0.75em;
}

.SearchResult .search-result-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Rubik-Medium';
  margin: 0 0 5px 0;
}

.SearchResult .search-result-subtitle .icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 15px 0 0;
  min-width: 15px;
}

.SearchBar.slim .search-result-subtitle .icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 0 10px 0 0;
}

.SearchResult .search-result-body {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space:initial;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.SearchResult .search-result-subtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 0 0 0.25vw 0;
  white-space: nowrap;
}

.SearchResult .search-result-subtitle .connection_string {
  font-size: 0.9em;
  flex-grow: 2;
  margin: 0 0 0 0;
  color: var(--color-dark-grey);
  display: flex;
}

.SearchBar.slim .no-results {
  padding: 5px 15px;
}

/* 
---------------------------------------------------------------
NotFound
--------------------------------------------------------------- 
*/

.page-not-found {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-top: 10vh;
}

.page-not-found-text {
  font-size: 1.5em;
  margin: 40px 0 0px 0;
}

.page-not-found-smalltext {
  font-size: 0.9em;
  margin: 20px 0 60px 0;
}

/* 
---------------------------------------------------------------
Home
--------------------------------------------------------------- 
*/
.Home {
  width: 100%;
  display: flex;
  flex: 1 1;
  flex-direction: column;
}

.large-nav-links {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin: 80px 0 0 0;
}

.large-nav-links-title {
  text-align: center;
  color: var(--color-dark-grey);
  letter-spacing: 0.1em;
  margin: 0 0 30px 0;
}

.large-nav-link-container-wrapper {
  margin: 0px auto 10px auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
  overflow-y: auto;
  position:  relative;
}

.large-nav-link-container {
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  position: absolute;
  top: 0px; bottom: 0; left: 0; right: 0;
}

.large-nav-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--color-very-light-grey);
  padding: 40px;
  border-radius: 10px;
  width: 280px;
  margin: 0 15px;
  transition: background 0.25s ease-out, box-shadow 0.25s ease-out;
}

.large-nav-link:hover {
  background: #fff;
  box-shadow: 0px 3px 10px #E0DAD499;
}

.large-nav-link .icon {
  width: 48px;
  height: 60px;
}

.large-nav-link .icon path.fill {
  visibility: hidden !important;
}

.large-nav-link .icon-datasets path {
  stroke: url(#gradient-datasets-yellow) !important;
}
.large-nav-link .icon-field-descriptions path {
  stroke: url(#gradient-field-descriptions) !important;
}
.large-nav-link .icon-business-terms path {
  stroke: url(#gradient-business-terms-blue) !important;
}

.large-nav-link-title {
  font-family: "Rubik-Medium";
  font-size: 1.4em;
  margin: 20px 0 10px 0;
}
.large-nav-link-description {
  color: var(--color-dark-grey);
  text-align: center;
}

.search-container {
  margin: 0 0 0 0;
  width: 100%;
  /*max-height: calc(100% - 50px);*/
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.search-header {
  background: var(--color-light-grey);
  padding: 40px 0 0 0;
}

.search-input input {
  border: none;
  border: 1px solid var(--color-medium-grey);
  border-radius: 10px;
  font-size: 1.4em;
  margin: 0 auto 0 auto;
  padding: 10px 10px 10px 60px;
  width: 100%;
  transition: transform 0.1s ease-in;
  background: url('./assets/img/icons/search.svg') #fff;
  background-repeat: no-repeat;
  background-position: 15px center;
  background-size: 30px;
}

.search-input.invalid input,
.search-input.invalid input:focus,
.search-input.invalid input:hover {
  border: 1px solid var(--color-intense-red);
}

.search-input {
  position: relative;
  width: 500px;
  margin: 0 auto 0 auto;
}

.search-input .error-message {
  display: none;
  position: absolute;
  color: var(--color-light-red);
  font-size: 0.7em;
  right: 10px;
  top: 2px;
}

.search-input.invalid .error-message {
  display: flex;
}

.search-input .button-clear-search {
  display: none;
  position: absolute;
  padding: 10px 5px;
  top: calc(50% - 19px);
  right: 10px;
  cursor: pointer;
}

.search-input .button-clear-search.show {
  display: block;
}

.search-input input:focus,
.search-input input:hover {
  border: 1px solid var(--color-black);
}

.search-input .button-clear-search:hover .button-clear-search-icon::after,
.search-input .button-clear-search:hover .button-clear-search-icon::before {
  background: var(--color-black);
}

.search-input .button-clear-search .button-clear-search-icon {
  transform: rotate(45deg);
  display: flex;
  align-items: center;
}

.search-input .button-clear-search .button-clear-search-icon::before {
  width: 20px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 0;
  top: 0vw;
  display: block;
}
.search-input .button-clear-search .button-clear-search-icon::after {
  width: 2px;
  height: 20px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: calc(-50%);
  top: 0;
  display: block;
}

.search-tip {
  visibility: hidden;
  width: 500px;
  margin: 20px auto 0 auto;
  padding: 20px 20px 20px 0;
  display: flex;
  height: 130px;
  flex-direction: row;
}

.search-tip.show {
  visibility: visible;
}

.search-tip-icon {
  margin-right: 20px;
}
.search-tip-list {
  display: flex;
  flex-direction: column;
}

.search-tip-show-all-button {
  color: var(--color-black);
  font-size: 0.9em;
  margin-top: 10px;
  cursor: pointer;
  align-self: flex-start;
}

.search-tip-show-all-button:hover {
  color: var(--color-purple);
}

.search-tip-text p {
  color: var(--color-dark-grey);
  font-size: 0.9em;
  white-space: pre-wrap;
}

.search-tip-text .search-tip-text-description {
  font-family: Rubik-Regular;
 
}

.search-tip-large-text {
  background: var(--color-very-light-grey);
  padding: 30px 40px;
  min-width: 300px;
  max-width: 500px;
  border-radius: 5px;
  margin: 0 15px 30px 15px;
  height: 180px;
}

.search-tip-large-text-title {
  font-family: Rubik-Medium;
  font-size: 1.0em;
}
.search-tip-large-text-example,
.search-tip-large-text-example-explanation {
  color: var(--color-dark-grey);
  white-space: pre-wrap;
}


/* 
---------------------------------------------------------------
Breadcrumbs
--------------------------------------------------------------- 
*/
.Breadcrumbs {
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.Breadcrumbs .divider {
  color: var(--color-dark-grey);
  display: flex;
    align-items: center;
  justify-content: center;
}

.Breadcrumbs .default-breadcrumb {
  margin: 0 0 0 5px;
  font-size: 1.4em;
  letter-spacing: 0.05em;
}

.Breadcrumbs .breadcrumb-item-container {
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
}
.Breadcrumbs .breadcrumb-item {
  padding: 5px;
  display: flex;
  flex-direction: row;
  font-family: 'Rubik-Regular';
}
.Breadcrumbs .breadcrumb-item span {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.Breadcrumbs .breadcrumb-item-text {
  text-overflow: ellipsis;
  display:  block !important;
  overflow: hidden;
  max-width: 200px;
}
.Breadcrumbs .breadcrumb-item .icon {
  margin-right: 10px;
}
.Breadcrumbs .link {
  text-decoration: none;
  font-family: 'Rubik-Light';
}

/* 
---------------------------------------------------------------
message-container
--------------------------------------------------------------- 
*/
.message-container {
  border: 1px solid #9AEBCF99;
  border-radius: 3px;
  padding: 15px;
  color: var(--color-intense-green);
  width: 30vw;
  min-width: 400px;
  margin-left: calc(50% - 15vw - 0px - 0px);
  position: absolute;
  top: -1000px;
  display: flex;
  flex-direction: row;
  opacity: 0;
  background-color: #CDF5E7;
  transition: opacity 0.25s ease-out, transform 0.25s ease-out, top 0s ease 0.25s;
  transform: scale(0.9);
  z-index: 2000;
  max-height: calc(50vh);
}

.message-container.show {
  opacity: 1;
  top: 10px;
  transform: scale(1);
  transition: opacity 0.25s ease-out, transform 0.25s ease-out;
}
.message-container.error {
  border: 1px solid var(--color-red);
  background-color: #FFDAD0;
  color: var(--color-intense-red);
}
.message-container .message-text-container {
  flex-grow: 1;
  flex-basis: max-content;
  display:  flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;
}

.message-container .message-text,
.message-container .message-details {
  
  word-break:break-word;
  white-space: pre-wrap;
}
.message-container .message-text {
  font-family: Rubik-Regular;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}
.message-container .message-details {
  margin-top:  10px;
  font-size: 0.9em;
  overflow-y: auto;
  overflow-x: hidden;
  word-break:break-word;
  width: 100%;
  white-space: pre-wrap;
  flex-wrap: wrap;
}

.message-container .message-details-toggle-button {
  font-size:  0.8em;
  margin-top:  10px;
  display: flex;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  justify-content: flex-start;
}

.message-container .message-details-toggle-button span {
  transform: rotate(135deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 7px;
  height: 7px; 
  margin: 0 3px 2px 5px;
  transition: transform 0.1s ease-out;
}
.message-container .message-details-toggle-button:hover {
  color: var(--color-black);
}
.message-container .message-details-toggle-button:hover span::after,
.message-container .message-details-toggle-button:hover span::before {
  background: var(--color-black);
}

.message-container .message-details-toggle-button.show span {
  transform: rotate(225deg);
}

.message-container  .message-details-toggle-button span::after,
.message-container  .message-details-toggle-button span::before {
  content: '';
  background: var(--color-intense-green);
  border-radius: 2px;
  left: 0px;
  top: 0px;
  position: absolute;
}
.message-container.error  .message-details-toggle-button span::after,
.message-container.error  .message-details-toggle-button span::before { 
  background: var(--color-intense-red);
}
.message-container .message-details-toggle-button span::after {
  width: 7px;
  height: 1px;
}
.message-container .message-details-toggle-button span::before {
  width: 1px;
  height: 7px;
}


.message-container .close-button {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 100%;
  transform: rotate(45deg);
  cursor: pointer;
  position: relative;
  z-index: 10;
}
.message-container .close-button::after {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-light-green);
  position: relative;
  left: 2px;
  top: 7px;
  display: block;
}
.message-container .close-button::before {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-light-green);
  position: relative;
  left: 2px;
  top: 9px;
  display: block;
  transform: rotate(90deg);
}
.message-container.error .close-button::before,
.message-container.error .close-button::after {
  background: var(--color-light-red);
}

.message-container .close-button:hover {
  background: var(--color-black);
}
.message-container .close-button:hover::after {
  background: #fff;
}
.message-container .close-button:hover::before {
  background: #fff;
}


/* 
---------------------------------------------------------------
main-toolbar
--------------------------------------------------------------- 
*/
.main-toolbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0;
  height: 40px;
}
.main-toolbar.align-right {
  justify-content: flex-end;
}
.main-toolbar.space-between {
  justify-content: space-between;
}

.main-toolbar .selected-items-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
.main-toolbar-title {
  font-size: 1.5em;
  font-family: 'Rubik-Medium';
  margin: 0 30px 0 0;
}
.main-toolbar-text {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  margin: 0 10px 0 30px;
}

.DataTableToolbar .main-button {
  margin-right: 30px !important;
}

.filter-container,
.filter-container form {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.DataTableToolbar .CustomInput .input {
  padding: 0 15px;
  width: 200px;
}
.DataTableToolbar .CustomInput .input-container-wrapper {
  border: 1px solid var(--color-medium-grey);
}
/*.DataTableToolbar .CustomInput:hover .input {
  border: 1px solid var(--color-black);
}*/

.DataTableToolbar .CustomCheckbox .label {
  font-size: 0.75em;
  font-family: Rubik-Medium;
}
.DataTableToolbar .CustomCheckbox {
  align-items: center;
  margin-right: 20px;
}
.DataTableToolbar .CustomCheckbox .icon-description {
  display: none;
}

.DataTableToolbar .view-modes-container {
  margin-left: 10px;
}

/*.DataTableToolbar .view-modes-container .select-container {
  border-color: var(--color-medium-grey);
}
.DataTableToolbar .view-modes-container .CustomSelectButtons .select-options {
  background: var(--color-medium-grey);
  padding: 0px;
}
.DataTableToolbar .view-modes-container .select-option {
  min-width: 33px;
}

.DataTableToolbar .view-modes-container .select-option.active {
  background: #fff;
}

.DataTableToolbar .view-modes-container .select-option.active .icon .fill {
  display: block;
}*/






.main-toolbar .morph-button-wrapper {
  display: flex;
}

.main-toolbar .morph-button-toggle {
  transition: opacity 0s ease 0.15s;
  margin-left: 0 !important;
}
.main-toolbar .morph-button-wrapper.show {

}
.main-toolbar .morph-button-wrapper .morph-button-content {
  width: 0px;
  transition: width 0.15s ease, opacity 0.05s ease 0.05s; /* hide input field */
  pointer-events: none;
  opacity: 0;
}
.main-toolbar .morph-button-wrapper.show .morph-button-content {
  width: 25vw;
  max-width: 500px;
  height: 100%;
  opacity: 1;
  pointer-events: auto;
  transition: width 0.15s ease, opacity 0.1s ease 0.05s; /* show input field */

}
.main-toolbar .morph-button-wrapper.show .morph-button-toggle {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0s ease;
  
}

.main-toolbar .morph-button-wrapper .CustomSelect .select-container,
.main-toolbar .morph-button-wrapper .CustomSelect .select-options,
.main-toolbar .morph-button-wrapper .CustomSelect .select-button-container,
.main-toolbar .CustomCheckbox .checkmark {
  background: #fff;
}

.form-block.inline {
  display: flex;
  flex-direction: row;
  /*width: 300px;*/
}

.form-block.inline label {
  margin-bottom: 0;
}




/* 
---------------------------------------------------------------
DataTable 
--------------------------------------------------------------- 
*/

.DataTableWrapper {
  display: flex;
  flex-grow: 2;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: auto;
  position: relative;
  margin-top: 10px;
}

.DataTable {
  border-collapse: separate; /* Don't collapse */
  border-spacing: 0;
  width: 100%;
  position: absolute;
  top: 0; bottom: 0; left: 0; right: 0;
}
.DataTable th, 
.DataTable td {
  padding: 10px 10px;
  max-width: 20vw;
  text-overflow: ellipsis;
  overflow: hidden;
}

.DataTable th {
  font-family: 'Rubik-Medium' !important;
  font-size: 0.75em;
  border-bottom: 2px solid var(--color-medium-grey);
  padding: 1px 10px 10px 10px;
  background: var(--color-very-light-grey);
  position: sticky;
  top: 0;
  z-index: 1;
  white-space: nowrap;
}

.DataTable th.checkbox {
  padding-bottom: 4px;
}


.DataTable .row td {
  border-top: 1px solid rgba(184,173,171,0.3);
}


.DataTable .cell-expansion {
  border-top: none;
}

.DataTable .row:first-child td {
  border-top: none;
}

.DataTable.no-header .row:first-child td {
  border-top: 1px solid rgba(184,173,171,0.3);
}

.DataTable {
  border-bottom: 1px solid rgba(184,173,171,0.3);
}

.DataTable .row.expanded td {
  background: #fff;
}

.DataTable .row.grouped td {
  border-top: 1px solid transparent;
}


.DataTable .row.background-white td,
.DataTable .row-expansion.background-white td  {
  background: #fff;
}

.DataTable .row-expansion.background-white .cell-expansion tr:hover td  {
  background: #F7EEFF;
}
.DataTable .row-expansion.background-white .cell-expansion tr:hover td:first-child  {
  border-radius: 5px 0 0 5px;
}
.DataTable .row-expansion.background-white .cell-expansion tr:hover td:last-child  {
  border-radius:  0 5px 5px 0;
}
.DataTable .row-expansion table.condensed-left td:last-child {
  width: calc(100% - 0px);
}

.DataTable .row-expansion table.condensed-left td {
  border-top: none !important;
}


.DataTable .cell-expansion .margin-10 {
  margin: 10px 0px;
}

.DataTable .cell-expansion {
  padding: 0 10px 0 40px;
}

.DataTable .cell-expansion table td,
.DataTable .cell-expansion table .row:last-child td {
  border-bottom: none;
  border-top: 1px solid rgba(184,173,171,0.2);
  vertical-align: top;
}

.DataTable .cell-expansion table.hide-header th {
  padding-right: 25px;
  height: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 0;
  border: none;
}
.DataTable .cell-expansion table.hide-header {
  margin-bottom: 0px;
}

.DataTable .cell-expansion table th {
  text-align: left;
  border-bottom: none;
  padding-bottom: 3px;
  padding-top: 10px;
  border-bottom: 2px solid rgba(184,173,171,0.2);
  position: initial;
  background: initial;

}

.DataTable .cell-expansion table {
  margin-bottom: 5px;
}

.DataTable .cell-expansion table td:nth-child(1) {
  margin-left: 20px;
}

.DataTable .row-expansion table {
  border-collapse: collapse;
  width: 100%;
}

.DataTable .checkbox {
 width: 20px;
}
.DataTable .CustomCheckbox {
  margin-top: -2px; /* Fix for tables where inline-flex elements with vertical-align: middle are not centered correctly in the cell */
}
.DataTable .checkbox:hover {
  cursor: pointer;
}
.DataTable .checkbox:hover .CustomCheckbox input ~ .checkmark {
  border-color: var(--color-black);
}
.DataTable .checkbox:hover .CustomCheckbox.disabled input ~ .checkmark {
  border-color: var(--color-medium-grey);
}
.DataTable .checkbox:active .CustomCheckbox .checkmark {
transform: scale(0.8) !important;
}
.DataTable .checkbox:active .CustomCheckbox.disabled .checkmark {
transform: none !important;
}


.DataTable .row:hover {
  background: #fff;
}

.DataTable .row.selected {
  background: #F7EEFF;
}

.DataTable th.sortable {
  cursor: pointer;
}

.DataTable .loading-dummy-row {
  height: 100px;
  text-align: center;
  vertical-align: top;
}
.DataTable .loading-dummy-row td {
  font-family: Rubik-Medium;
  padding:  20px 0 0 0;
}


/* Sort arrow */
.DataTable th.sortable::after,
.DataTable th.sortable.asc::after, 
.DataTable th.sortable:hover::after,
.DataTable th.sortable.desc:hover::after {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  content: '';
  border-bottom: 5px solid var(--color-black);
  border-top: none;
  margin: 10px 0 3px 10px;
}
.DataTable th.sortable::after {
  border-bottom: 5px solid transparent;
}
.DataTable th.sortable.desc::after,
.DataTable th.sortable.desc:hover::after {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  display: inline-block;
  content: '';
  border-top: 5px solid var(--color-black);
  border-bottom: none;
  margin: 10px 0 3px 10px;
}

.DataTable .left {
 text-align: left;
}
.DataTable .right {
 text-align: right;
}
.DataTable .center {
 text-align: center;
}

.DataTable .no-right-margin {
 padding-right: 0 !important;
}
.DataTable .no-right-margin .icon {
 margin-right: 0;
}

.DataTable .bold {
  font-family: 'Rubik-Regular';
}

.DataTable .button {
  height:  30px;
}

.DataTable .toggle-expand {
  padding: 0;
  width: 30px;
  max-width: 30px;
}

.button-toggle-expand {
  transform: rotate(135deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px; 
  margin: 0 3px 0 0;
  cursor: pointer;
  transition: transform 0.1s ease-out;
  border-radius: 50%;
}

.button-toggle-expand.expanded {
  transform: rotate(225deg);
}

.button-toggle-expand::after,
.button-toggle-expand::before {
  content: '';
  background: var(--color-dark-grey);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
  transition: background 0.1s ease-out;
}
.button-toggle-expand::after {
  width: 8px;
  height: 2px;
  left: 12px;
  top: 12px;
}
.button-toggle-expand::before {
  width: 2px;
  height: 8px;
  left: 12px;
  top: 12px;
}
.button-toggle-expand:hover::after,
.button-toggle-expand:hover::before {
  background: var(--color-black);
}


.button-toggle-collapse-all {
  transform: rotate(45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px; 
  margin: 0 3px 0 0;
  cursor: pointer;
  transition: transform 0.1s ease-out;
  border-radius: 50%;
}

.button-toggle-collapse-all .arrow-1::after,
.button-toggle-collapse-all .arrow-1::before {
  content: '';
  background: var(--color-dark-grey);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
  transition: background 0.1s ease-out;
}
.button-toggle-collapse-all .arrow-1::after {
  width: 8px;
  height: 2px;
  left: 14px;
  top: 14px;
}
.button-toggle-collapse-all .arrow-1::before {
  width: 2px;
  height: 8px;
  left: 14px;
  top: 14px;
}

.button-toggle-collapse-all .arrow-2::after,
.button-toggle-collapse-all .arrow-2::before {
  content: '';
  background: var(--color-dark-grey);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
  transition: background 0.1s ease-out;
}
.button-toggle-collapse-all .arrow-2::after {
  width: 8px;
  height: 2px;
  left: 10px;
  top: 10px;
}
.button-toggle-collapse-all .arrow-2::before {
  width: 2px;
  height: 8px;
  left: 10px;
  top: 10px;
}

.button-toggle-collapse-all:hover .arrow-1::after,
.button-toggle-collapse-all:hover .arrow-1::before,
.button-toggle-collapse-all:hover .arrow-2::after,
.button-toggle-collapse-all:hover .arrow-2::before {
  background: var(--color-black);
}

.DataTable .button span {
  font-size: 0.9em;
  height: 30px;
}

.DataTable td.small-text {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  line-height: 19px;
}

.DataTable td.small-bold-text {
  font-size: 0.75em;
  font-family: Rubik-Medium;
  line-height: 18px;
}
.DataTable td.no-break {
  white-space: nowrap;
}
.DataTable td.width-150 {
  min-width: 150px !important;
}

.DataTable .chart-label {
  white-space: nowrap;
}

.DataTable .link {
  text-decoration: none;
  font-family: inherit;
}

.DataTable .type-photo {
  padding: 0 0 0 10px !important;
  width: 30px;
}
.DataTable .photo-img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.DataTable .type-button {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.DataTable .with-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DataTable .with-icon .icon {
  margin: 0 10px 0 0;
} 
.DataTable .type-icon {
  width: 30px;
  min-width: 30px;
  padding-right: 0 !important;
  padding-left: 0 !important;
}
.DataTable .type-icon-header {
  width: 30px;
  min-width: 30px;
}

.DataTable .type-boolean {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.DataTable td.type-pill {
  width: 70px;
  min-width: 70px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
} 

.DataTable .type-pill-wide {
  width: 90px;
  min-width: 90px;
}

.DataTableWrapper .empty-text {
  margin: 40px 10px;
  font-style: italic;
  color: var(--color-dark-grey);
}

.DataTable .chart-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.DataTable .chart-container .bar {
  height: 2px;
  background-color: var(--color-black);
}
.DataTable .chart-container .bar-bg {
  height: 2px;
  background-color: var(--color-medium-grey);
}

.DataTable .CustomTextareaReadOnly .public-DraftStyleDefault-block {
  margin: 0;
}
.DataTable .hide-column {
  display: none;
}

.pill {
  font-size: 0.65em;
  font-family: 'Rubik-Regular';
  text-transform: uppercase;
  border: 1px solid var(--color-medium-grey);
  border-radius: 50px;
  padding: 5px 10px;
  display: inline-block;
  text-align: center;
  min-width: 70px;
}

.pill-wide {
  min-width: 90px;
}

.pill-full-red {
  border: 1px solid var(--color-intense-red);
  background: var(--color-intense-red);
  color: #fff;
}

.pill-red {
  border: 1px solid #FFBEAB;
  color: var(--color-intense-red);
}

.pill-green {
  border: 1px solid var(--color-light-green);
  color: var(--color-intense-green);
}

.pill-blue {
  border: 1px solid var(--color-light-blue);
  color: var(--color-intense-blue);
}

.pill-grey {
  color: var(--color-dark-grey);
}

.DataTable .chart {
  max-width: 10vw;
  min-width: 5vw;
  padding-left: 0;
}

.DataTable .last-column {
  padding: 0px !important;
}

.DataTable .last-column.width-1 {
  width: 0px;
}
.DataTable .last-column.width-1 {
  width: 30px;
  min-width: 30px;
}
.DataTable .last-column.width-2 {
  width: 60px;
  min-width: 60px;
}
.DataTable .last-column.width-3 {
  width: 90px;
  min-width: 90px;
}

.DataTable .last-column .buttons {
  display: none;
}

.DataTable .row:hover .last-column .buttons {
  display: flex;
  flex-direction: row;
}

.DataTable .row:hover .last-column .buttons div:nth-child(n+2) {
  margin-left: 5px;
}

.pagination {
  display:  flex;
  flex-direction:  row;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-top: 1px solid rgba(184,173,171,0.3);
}
.pagination-page-button {
  display:  flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 0 1px;
  cursor: pointer;
  min-width: 30px;
  height: 30px;
  flex-grow: 0;
  border-radius: 50px;
}
.pagination-page-buttons {
  display:  flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.pagination-page-button:hover, .pagination-page-button.active {
  background: var(--color-black);
  color: #FFF;
}

.pagination-page-button:active {
  transform: scale(0.95);
}

.pagination-page-button.step-button {
  padding: 5px 10px;
  margin: 0 15px;
  font-size:  0.8em;
  text-transform: uppercase;
  min-width: 70px;
}

.pagination-page-button.step-button.prev .button-arrow {
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 10px;
  height: 10px; 
  margin: 0 3px 0 0;
}
.pagination-page-button.step-button.next .button-arrow {
  transform: rotate(135deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 10px;
  height: 10px; 
  margin: 0 0 0 3px;
}

.pagination-page-button.step-button .button-arrow::after,
.pagination-page-button.step-button .button-arrow::before {
  content: '';
  background: var(--color-black);
  position: absolute;
  border-radius: 2px;
  left: 0px;
  top: 0px;
}
.pagination-page-button.step-button .button-arrow::after {
  width: 10px;
  height: 2px;
}
.pagination-page-button.step-button .button-arrow::before {
  width: 2px;
  height: 10px;
}
.pagination-page-button.step-button:hover .button-arrow::after,
.pagination-page-button.step-button:hover .button-arrow::before {
  background: #fff;
}


/* 
---------------------------------------------------------------
Side Dialog 
--------------------------------------------------------------- 
*/

.SideDialog {
  position: absolute;
  z-index: 10;
  top: 50px;
  display: flex;
  flex-direction: column;
  /* Handled in component to enable resizing functionality */
  /*right: -450px;*/
  /*width: 450px;*/
  min-width: 300px;
  max-width: calc(70vw - 250px);
  height: calc(100% - 50px);
  background: #fff;
  border-left: 1px solid var(--color-medium-grey);
  transition: right 0.1s ease-out, filter 0.2s ease-out;
}
.side-dialog-container {
  padding: 25px 0 25px 30px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  overflow: hidden;
}
.SideDialog.show {
  right: 0 !important;
}

.SideDialog .side-dialog-header {
 display: flex;
 flex-direction: row;
 justify-content: space-between; 
 margin: 0 30px 15px 0;
}

.SideDialog .side-dialog-content {
 display: flex;
 flex-grow: 2;
 flex-direction: column;
 justify-content: space-between; 
 overflow: hidden;
}

.SideDialog .side-dialog-footer {
 margin: 20px 30px 0 0;
}

.SideDialog h2 {
  font-family: 'Rubik-Medium';
  font-size: 1.5em;
}

.SideDialog .close-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transform: rotate(45deg);
  position: relative;
  cursor: pointer;
}
.SideDialog .close-button::after {
  width: 20px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 5px;
  top: 12px;
  display: block;
}
.SideDialog .close-button::before {
  width: 20px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-black);
  position: relative;
  left: 5px;
  top: 14px;
  display: block;
  transform: rotate(90deg);
}
.SideDialog .close-button:hover {
  background: var(--color-black);
}
.SideDialog .close-button:hover::after {
  background: #fff;
}
.SideDialog .close-button:hover::before {
  background: #fff;
}

.SideDialog .resize-control {
  width: 3px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: ew-resize;
  border-left: 1px solid transparent;
}

.SideDialog .resize-control:hover,
.SideDialog .resize-control:active {
  border-left: 1px solid #22202033;
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Chrome, Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.SideDialog .CustomInput .input-container-wrapper {
  background: var(--color-very-light-grey);
}
.SideDialog .CustomInput .input {
  background: none;
}


.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
}

.SideDialog .form {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 30px 0 0;
}

.form form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 2;
}

.form-content {
  width:  390px;
  margin: 15px 0 0 0;
  display: flex;
  flex-direction: column;
}

.form-content .CustomSelect .select-container,
.form-content .CustomSelect .select-options,
.form-content .CustomSelect .select-button-container,
.form-content .CustomCheckbox .checkmark  {
  background: #fff; 
}

.form-block {
  display: flex;
  margin: 10px 0 10px 0;
}

.form-block > div:nth-child(n+2) {
  margin: 5px 0 0 0;
}

.form-block .button {
  align-self: flex-start;
}

.form-block.vertical {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
}

.form-block.horizontal {
  display: flex;
  align-items: center;
}

.form .horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form .horizontal .icon {
  margin: 0 15px 0 0;
}

.form-block.vertical * {
}

.form-block.horizontal > * {
  margin-right: 10px !important;
}

.form-text {
  padding: 0 15px 10px 15px;
}

.form-light-text {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  margin: 10px 0 0 0;
  cursor: default;
  word-break: break-word;
}
.form-light-text label {
  font-size: 0.9em !important;
  margin: 5px 0 !important;
  
}

.form-light-text.no-margin {
  margin: 0 0 0 0;
}

.form-block.disabled label {
  color: var(--color-dark-grey);
}
/*
.form textarea,
.form input[type="text"],
.form input[type="email"],
.form input[type="password"] {
  border: solid 1px var(--color-light-grey);
  background: var(--color-very-light-grey);
  padding: 10px 15px 10px 15px;
  clear: both;
  border-radius: 3px;
  width: 100%;
}*/

.form textarea {
  max-height: 30vh;
  border: solid 1px var(--color-light-grey);
  background: var(--color-very-light-grey);
  padding: 10px 15px 10px 15px;
  border-radius: 3px;
  width: 100%;
  resize: none;
}

.form textarea:focus,
.form textarea:hover {
  border: solid 1px var(--color-black);
}
.form textarea:disabled {
  color: var(--color-dark-grey);
}
.form textarea:disabled:hover {
  border: solid 1px var(--color-light-grey);
}

.form label {
  margin: 0 0 10px 0;
  display: flex;
  font-size: 0.75em;
  font-family: 'Rubik-Medium';
}

.form label .label-link {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
  color: var(--color-dark-grey);
  font-family: 'Rubik-Regular';
}
.form label .label-link a {
  display: flex;
  margin: 0 0 0 10px;
  align-items: center;
  color: var(--color-dark-grey);
}
.form label .label-link a:hover {
  color: var(--color-black);
}
.form label .label-link a .icon {
  margin: 0 5px 0 0;
}
.form .CustomCheckbox {
  margin: 0 0 0vw 0 !important;
}

.form label .CustomCheckbox {
  font-size: 1em;
}

.connection-test-cancel {
  cursor: pointer;
  flex-grow: 2;
  text-align: right;
}

.connection-test-cancel:hover {
  color: var(--color-black);
}

.connection-test-result {
  margin: 10px 0 0 0;
  font-size: 0.8em;
  color: var(--color-dark-grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  width:  100%;
  white-space: pre-line;
}
.connection-test-result.failed {
  color: var(--color-intense-red);
}

.connection-test-result.warning {
  color: var(--color-intense-orange);
}

.connection-test-result-icon.success {
  width: 18px;
  height: 18px;
  margin: -10px 10px 0 10px;
  position: relative;
  transform: rotate(35deg);
}
.connection-test-result-icon.success::before {
  position: absolute;
  right: 0px;
  top: 0;
  content: '';
  display: block;
  width: 3px;
  height: 18px;
  background: var(--color-intense-green);
  border-radius: 2px;
}
.connection-test-result-icon.success::after {
  position: absolute;
  right: 0px;
  bottom: 0px;
  content: '';
  display: block;
  width: 10px;
  height: 3px;
  background: var(--color-intense-green);
  border-radius: 2px;
}
/*Medium Red:   FF3F07
Medium Green: 2BCA93*/


/* 
---------------------------------------------------------------
FormTabs
--------------------------------------------------------------- 
*/
.FormTabs .tab-list {
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 0;
}
.FormTabs .tab-content {
  display: flex;
  flex-direction: column;
}
.FormTabs .tab-list-item {
  flex-grow: 1;
  font-family: 'Rubik-Medium';
  font-size: 0.8em;
  color: var(--color-black);
  padding: 10px;
  cursor: pointer;
  border-bottom: 2px solid var(--color-medium-grey);
}
.FormTabs .tab-list-item:hover,
.FormTabs .tab-list-item:focus {
  border-bottom: 2px solid var(--color-black);
}
.FormTabs .tab-list-item.active {
  color: var(--color-black);
  border-radius: 3px 3px 0 0;
  border-bottom: 2px solid var(--color-black);
}

/* 
---------------------------------------------------------------
Icons
--------------------------------------------------------------- 
*/
.icon {
  width: 1.2em;
  height: 1.2em;
  content: "";
  margin: 0 auto 0 auto;
  display: block;
}

/* Datatypes */
.icon-binary {
  background: url('./assets/img/icons/binary.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.1em 1.1em;
}
.icon-boolean {
  background: url('./assets/img/icons/boolean.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.0em 1.0em;
}
.icon-date {
  background: url('./assets/img/icons/date.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.0em 1.0em;
}
.icon-datetime {
  background: url('./assets/img/icons/datetime.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-number {
  background: url('./assets/img/icons/number.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.9em 0.9em;
}
.icon-string {
  background: url('./assets/img/icons/string.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-geography {
  background: url('./assets/img/icons/geography.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.1em 1.1em;
}
.icon-array {
  background: url('./assets/img/icons/array.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.2em 1.2em;
}
.icon-tag {
  background: url('./assets/img/icons/tag.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.4em 1.4em;
}
.icon-unknown {
  background: url('./assets/img/icons/unknown.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.0em 1.0em;
}

.icon-primary-key {
  background: url('./assets/img/icons/primary key.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4em;
}

.icon-foreign-key {
  background: url('./assets/img/icons/foreign key.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4em;
}

.icon-pii {
  background: url('./assets/img/icons/pii.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4em;
}

/* Dataset Types */
.icon-api, .icon-api-endpoint, .icon-object {
  background: url('./assets/img/icons/api.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4em;
}
.icon-cube, .icon-ssas-cube {
  background: url('./assets/img/icons/cube.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-excel-file {
  background: url('./assets/img/icons/excel file.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-file {
  background: url('./assets/img/icons/file.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-stored-procedure {
  background: url('./assets/img/icons/stored procedure.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-table {
  background: url('./assets/img/icons/table.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-tableau-data-source {
  background: url('./assets/img/icons/tableau data source.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-view {
  background: url('./assets/img/icons/view.svg');
  background-repeat: no-repeat;
  background-position: center;
}

/* Datasource Types */
.icon-database, 
.icon-odbc {
  background: url('./assets/img/icons/database.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-folder {
  background: url('./assets/img/icons/folder.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4em;
}
.icon-tableau-server {
  background: url('./assets/img/icons/tableau server.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-transformation {
  background: url('./assets/img/icons/transformation.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-databricks {
  background: url('./assets/img/icons/databricks.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-dbt {
  background: url('./assets/img/icons/dbt.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1.1em 1.1em;
}
.icon-ibmdb2 {
  background: url('./assets/img/icons/ibm_db2.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-mssql {
  background: url('./assets/img/icons/mssql.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-oracle {
  background: url('./assets/img/icons/oracle.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-postgres {
  background: url('./assets/img/icons/postgres.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.icon-snowflake {
  background: url('./assets/img/icons/snowflake.svg');
  background-repeat: no-repeat;
  background-position: center;
}



/* Field Roles */
.icon-measure {
  background: url('./assets/img/icons/measure.svg');
  background-repeat: no-repeat;
  background-position: center;
  height: 1.1em;
}
.icon-dimension {
  background: url('./assets/img/icons/dimension.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4em;
}

/* Other */
.icon-katalogue {
  background: url('./assets/img/icons/katalogue.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 2.4em;
  height: 2.4em;
}
.icon-katalogue-white {
  background: url('./assets/img/icons/katalogue white.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 2.2em;
  height: 2.2em;
}
.icon-profile {
  width: 24px;
  height: 30px;
}
.icon-password {
  width: 24px;
  height: 30px;
}
.icon-signout {
  width: 24px;
  height: 30px;
}

.icon-home,
.icon-datasets,
.icon-field-descriptions,
.icon-business-terms,
.icon-user-groups,
.icon-users,
.icon-connections,
.icon-tasks,
.icon-settings,
.icon-field-units {
  width: 24px;
  height: 30px;
}

.icon-edit {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url('./assets/img/icons/edit.svg');
  background-repeat: no-repeat;
  background-size: 24px 48px;
  background-position: 0 0;
}
.icon-delete {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url('./assets/img/icons/delete.svg');
  background-repeat: no-repeat;
  background-size: 24px 48px;
  background-position: 0 0;
}
.icon-remove {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url('./assets/img/icons/remove.svg');
  background-repeat: no-repeat;
  background-size: 24px 48px;
  background-position: 0 0;
}
.icon-run {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: url('./assets/img/icons/run.svg');
  background-repeat: no-repeat;
  background-size: 24px 48px;
  background-position: 0 0;
}
.icon-edit:active,
.icon-delete:active,
.icon-remove:active,
.icon-run:active  {
  transform: scale(0.9);
}

.icon-edit:hover,
.icon-delete:hover,
.icon-remove:hover,
.icon-run:hover  {
  background-position: 0 -24px;
}

.icon-run.disabled {
  opacity: 0.3;
}
.icon-run.disabled:hover {
  background-position: 0 0;
  cursor: initial;
}
.icon-run.disabled:active {
  transform: none
}

.icon-filter {
  width: 23px;
  height: 23px;
  background: url('./assets/img/icons/filter.svg');
  background-repeat: no-repeat;
}

.icon-descriptions,
.icon-statistics {
  width: 24px;
  height: 24px;
}

.icon-descriptions .fill,
.icon-statistics .fill {
  display: none;
}

.icon-fit {
  width: 21px;
  height: 21px;
  background: url('./assets/img/icons/fit.svg');
  background-repeat: no-repeat;
}

.icon-reload {
  width: 21px;
  height: 21px;
  background: url('./assets/img/icons/reload.svg');
  background-repeat: no-repeat;
}

.icon-node-size {
  width: 23px;
  height: 23px;
  background: url('./assets/img/icons/node size.svg');
  background-repeat: no-repeat;
}
.icon-search {
  width: 23px;
  height: 23px;
  background: url('./assets/img/icons/search.svg') #fff;
  background-repeat: no-repeat;
}
.icon-arrow-left {
  background: url('./assets/img/icons/arrow left.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
}
.icon-arrow-right {
  background: url('./assets/img/icons/arrow right.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
}

/* Task status icons */
.icon-running {
  background: url('./assets/img/icons/running.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2em;
  height: 1.2em;
  animation: spin 2s linear infinite;
}
.icon-completed {
  background: url('./assets/img/icons/completed.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2em;
  height: 1.2em;
}
.icon-pending {
  background: url('./assets/img/icons/pending.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2em;
  height: 1.2em;
}
.icon-failed {
  background: url('./assets/img/icons/failed.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2em;
  height: 1.2em;
}
.icon-warning {
  background: url('./assets/img/icons/warning.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2em;
  height: 1.2em;
}
.icon-aborted {
  background: url('./assets/img/icons/aborted.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2em;
  height: 1.2em;
}
.icon-skipped {
  background: url('./assets/img/icons/skipped.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2em;
  height: 1.2em;
}

/* Textarea editor buttons */
.icon-editor {
  width: 14px;
  height: 14px;
  content: "";
  margin: 0 auto 0 auto;
  display: block;
}

.icon-tip {
  background: url('./assets/img/icons/tip.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 60px;
  height: 60px;
}

.icon-owner {
  background: #B8ADAB29;
  color: var(--color-dark-grey);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  margin: 0;
  font-family: Rubik-Regular;
}

.icon-user-photo {
  background: url('./assets/img/icons/user avatar.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.icon-group-photo {
  background: url('./assets/img/icons/user group avatar.svg');
  background-repeat: no-repeat;
  background-position: center;
}

.icon-service-account-photo {
  background: url('./assets/img/icons/service account avatar.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px 30px;

}

.icon-not-found {
  width: 160px !important;
  height: 160px !important;
}

.icon-not-found path {
  fill: url(#icon-not-found-gradient) !important;
}

.icon-caps-lock {
  background: url('./assets/img/icons/caps lock.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
}


.icon-editor_table {
  background: url('./assets/img/icons/editor table.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
}
.icon-editor_lineage {
  background: url('./assets/img/icons/editor lineage.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
}
.icon-editor_relationships {
  background: url('./assets/img/icons/editor relationships.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
}
.icon-editor_code {
  background: url('./assets/img/icons/editor code.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 14px;
  height: 14px;
}
.icon-small_descriptions {
  width: 14px;
  height: 14px;
}

.icon-small_descriptions .fill {
  display: none;
}

@keyframes spin { 
  100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } 
}

/* 
---------------------------------------------------------------
CustomButton
--------------------------------------------------------------- 
*/

.CustomButton {
  min-width:  35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.CustomButton.white {
  background: #fff;
  border: 1px solid var(--color-medium-grey);
  border-radius:  3px;
}

.CustomButton.white:hover,
.CustomButton.white:active {
  border: 1px solid var(--color-black);
}

.CustomButton.white:active {
  transform:  scale(0.95);
}


.button {
  cursor: pointer;
  font-family: 'Rubik-Regular';
  text-transform: uppercase;
  border-radius: 5px;
  border:  none;
  background: none;
  height: 35px;

}
.button:nth-child(n+2) {
  margin: 0 0 0 5px;

}
.button span {
  display: flex;
  border-radius: 5px;
  padding: 0px 13px 0px 13px;
  transition: background-color 0.1s ease-out; 
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 35px;
  min-width: 50px;
  white-space: nowrap;
  background: var(--color-light-grey);
}

.large-button {
  font-size: 1.5em;
  font-family: 'Rubik-Medium';
  text-transform: uppercase;
  border-radius: 100px;
  width: 100%;
  margin: 0 !important;
  height: auto;
  max-width: 400px;
  background: #E4C3FF; /* fallback for browsers that cannot do gradient backgrounds */
  background: linear-gradient(170deg, var(--color-pink), #E4C3FF 80%);
}
.large-button span {
  display: block;
  border-radius: 100px;
  border: none;
  background: var(--color-black);
  color: #fff;
  height: auto;
  padding: 10px 25px;
  transition: background 0.1s ease-out, transform 0.1s ease-in;
}

.main-button {
  background: #E4C3FF; /* fallback for browsers that cannot do gradient backgrounds */
  background: linear-gradient(150deg, var(--color-pink), #E4C3FF 80%);
  color: #fff;
  border:  none; 
}

.main-button span {
  background: rgba(35,31,32,0);
  border: none;
}

.secondary-button {
  display: inline-flex !important;
}
.secondary-button span {
  background: var(--color-light-grey);
  display: inline-flex !important;
  border: none;
}

.button:disabled,
.button.disabled,
.button.loading {
  cursor: initial;
}

.button:disabled span,
.button:disabled:hover span,
.button.disabled span,
.button.disabled:hover span {
  color: var(--color-dark-grey);
  background: var(--color-light-grey);
  transform: none !important;
  cursor: default;
}

.main-button:disabled span,
.large-button:disabled span,
.main-button:disabled:hover span,
.large-button:disabled:hover span,
.main-button.disabled span,
.large-button.disabled span,
.main-button.disabled:hover span,
.large-button.disabled:hover span {
  background: var(--color-light-grey);
}


.button.loading span,
.button.loading:hover span,
.button.loading {
  color: var(--color-dark-grey) !important;
  background-color: var(--color-light-grey);
  animation: button-loading-animation 1.2s ease-in-out infinite;
  background-size: 200px 100%;
  background-repeat: no-repeat;
  background-image: linear-gradient(
      90deg,
      rgba(255,255,255,0),
      rgba(255,255,255,1),
      rgba(255,255,255,0)
    ) !important;
}

@keyframes button-loading-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.main-button:hover span,
.button:hover span {
  background: rgba(35,31,32,1);
  color: #fff;
}

.large-button:hover span {
  background: rgba(35,31,32,0);
}

.large-button:hover {
  background: linear-gradient(170deg, var(--color-pink), #E4C3FF 80%);
}

.button:active {
  transform: scale(0.95) !important;
}

.button.loading:active,
.button:disabled:active,
.button.disabled:active {
  transform: none !important;
}


/* 
---------------------------------------------------------------
CustomCheckbox 
--------------------------------------------------------------- 
*/
/* Customize the label (the container) */
.CustomCheckbox {
  display: inline-flex;
  align-items: flex-start;
  vertical-align: middle;
  justify-content: center;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  margin: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: max-content;
}

/* Hide the browser's default checkbox */
.CustomCheckbox input {
  position: absolute;
  display: none;
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
}

.CustomCheckbox span {
  vertical-align: middle;
  display: inline-flex;
}

.CustomCheckbox .label {
  margin: 0 0 0 10px;
}

.CustomCheckbox.label-position-left {
  display: flex;
  flex-direction: row-reverse;
}

.CustomCheckbox.label-position-left .label {
  margin:  0 10px 0 0;
  min-width: 100px;
}

.CustomCheckbox.disabled .label {
  color: var(--color-dark-grey);
}

/* Create a custom checkbox */
.CustomCheckbox .checkmark {
  height: 17px;
  width: 17px;
  border-radius: 4px;
  border: 1px solid var(--color-medium-grey);
  cursor: pointer;
  background: var(--color-very-light-grey);
  position: relative;
}
.CustomCheckbox.disabled,
.CustomCheckbox.disabled .checkmark {
  cursor: default;
}
/* On mouse-over */
.CustomCheckbox:hover input ~ .checkmark {
  border-color: var(--color-black);
}

.CustomCheckbox.disabled:hover input ~ .checkmark {
  border-color: var(--color-medium-grey);
}

/* When the checkbox is checked */
.CustomCheckbox input:checked ~ .checkmark {
  background: var(--color-black);
  height: 17px;
  width: 17px;
  border: 1px solid transparent;
}

.CustomCheckbox input:disabled:checked ~ .checkmark,
.CustomCheckbox.dim-selected .checkmark {
  background: var(--color-medium-grey);
}
.CustomCheckbox:active .checkmark {
transform: scale(0.8) !important;
}
.CustomCheckbox.disabled:active .checkmark {
transform: none !important;
}

/* Style the checkmark/indicator */
.CustomCheckbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
} 

.CustomCheckbox .checkmark.null:after {
  content: "";
  position: absolute;
  display: block;
  left: 3px;
  top: 3px;
  width: 7px;
  height: 7px;
  background: #fff;
  border-radius: 2px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
} 

/* Show the checkmark when checked */
.CustomCheckbox input:checked ~ .checkmark:after,
.CustomCheckbox.dim-selected .checkmark:after {
  display: block;
}

.CustomCheckbox .checkmark-icon {
  height: 28px;
  width: 28px;
  border-radius: 4px;
  border: 1px solid var(--color-medium-grey);
  cursor: pointer;
  background: var(--color-medium-grey);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomCheckbox:hover .checkmark-icon {
  background: #F7EEFF;
}

.CustomCheckbox input:checked ~ .checkmark-icon {
  background: #fff;
}

.CustomCheckbox input:checked ~ .checkmark-icon path {
  display: none;
}

.CustomCheckbox input:checked ~ .checkmark-icon .fill {
  display: block;
}

/* 
---------------------------------------------------------------
CustomSelect
--------------------------------------------------------------- 
*/

.CustomSelect {
  width: 100%;
}

.CustomSelect label {
  display:  flex;
  flex-direction: row;
}

.CustomSelect .select-container {
  position: relative;
  background-color: var(--color-very-light-grey);
  border-radius: 3px;
  min-height: 35px;
  width: 100%;
  transition: width 0.1s ease-out;
}

.CustomSelect .select-input-container,
.CustomSelect .select-input {
  margin: 0;
  height: 35px;
}

.CustomSelect .select-input-container {
  position: absolute;
  width: 100%;
  z-index: 1;
  overflow: hidden;
}
.CustomSelect .select-input {
  margin: 0;
  background: none !important; 
  border: solid 1px var(--color-light-grey);
  padding: 10px 15px 10px 15px;
  clear: both;
  border-radius: 3px;
  width: 100%;
}

.CustomSelect .select-input:hover,
.CustomSelect .select-input:focus {
  border: solid 1px var(--color-black);
}

.CustomSelect .select-input:disabled {
  color: var(--color-dark-grey);
}
.CustomSelect .select-input:disabled:hover {
  border: solid 1px var(--color-light-grey) !important;
}

.CustomSelect .input-icon {
  position: absolute;
  top: 0;
  left: 0px;
  width: 33px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.CustomSelect .select-value-container.disabled .select-value-label {
  color: var(--color-dark-grey);
}

.CustomSelect .select-container.show .select-input {
  border: 1px solid var(--color-black);
  border-radius: 3px 3px 0 0;  
 
}
.CustomSelect .select-container.show.up .select-input {
  border-radius: 0 0 3px 3px;   
}


.CustomSelect .select-value-container { 
  position: absolute;
  margin: 0 10px 0 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
  width:  calc(100% - 25px);
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  transition: width 0.1s ease-out;
}
.CustomSelect .select-value-container.placeholder {
  color: var(--color-dark-grey);
}
.CustomSelect .select-value { 
  display: flex;
  flex-direction: row;
  align-items: center;
}
.CustomSelect .select-value-label { 
  margin: 0;
}

.CustomSelect .select-input-container:hover .select-input {
  border: 1px solid var(--color-black);
}


.CustomSelect .select-button-container {
  position: absolute; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  right: 0.3vw;
  background-color: var(--color-very-light-grey);
  top: 1px; /*calc(50% - (1.2em + 15px)/2);*/
}
.CustomSelect .select-button {
  display: flex;
  justify-content: center;
  align-items: center;
  content: '';
  width: 30px;
  height: 33px;
  /*background-color: var(--color-very-light-grey);*/
  border-radius: 3px;
}

.CustomSelect .select-input-container:hover .button-toggle-menu,
.CustomSelect .select-container.show .button-toggle-menu {
  /*background-color: #fff;*/
}

.CustomSelect .button-clear-selection:hover .button-clear-selection-icon::after,
.CustomSelect .button-clear-selection:hover .button-clear-selection-icon::before {
  background: var(--color-black);
}

/*.CustomSelect .button-toggle-menu::after {
  display: block;
  content: '';
  width: 0vw;
  height: 0vw;
  border-top: 7px solid var(--color-black);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;

}*/
.CustomSelect .button-toggle-menu .button-toggle-menu-icon {
  transform: rotate(-135deg);
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.CustomSelect .button-toggle-menu-icon::before,
.CustomSelect .button-toggle-menu-icon::after  {
  content: '';
  border-radius: 2px;
  background: var(--color-dark-grey);
  position: absolute;
  display: block;
}

.CustomSelect .select-input-container:hover .button-toggle-menu-icon::before,
.CustomSelect .select-input-container:hover .button-toggle-menu-icon::after,
.CustomSelect .select-container.show .button-toggle-menu-icon::before,
.CustomSelect .select-container.show .button-toggle-menu-icon::after {
  background: var(--color-black);
}

.CustomSelect .button-toggle-menu-icon::before  {
  width: 9px;
  height: 3px;
  left: 0;
  top: 0vw;
}

.CustomSelect .button-toggle-menu-icon::after  {
  width: 3px;
  height: 9px;
  left: 0;
  top: 0;
}

.CustomSelect .button-clear-selection {
  cursor: pointer;
}

.CustomSelect .button-clear-selection .button-clear-selection-icon {
  transform: rotate(45deg);
  display: flex;
  align-items: center;
}

.CustomSelect .button-clear-selection .button-clear-selection-icon::before {
  width: 12px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 0;
  top: 0vw;
  display: block;
}
.CustomSelect .button-clear-selection .button-clear-selection-icon::after {
  width: 2px;
  height: 12px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: calc(-50%);
  top: 0;
  display: block;
}

.CustomSelect .select-options {
  display: none;
  position: absolute;
  z-index: 2;
  width: 100%;
  border-top: 1px solid #E0D4D0;
  border-left: 1px solid var(--color-black);
  border-right: 1px solid var(--color-black);
  border-bottom: 1px solid var(--color-black);
  border-radius: 0 0 3px 3px;
  margin-top: calc(35px - 1px);
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: var(--color-very-light-grey);
}

.CustomSelect .select-options-content {

}

.CustomSelect .select-options-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  font-size: 0.9em;
  color: var(--color-dark-grey);
}
.CustomSelect .select-options-header .button-select-all label {
  font-size: 1.0em;
  font-family: Rubik-Light;
}
.CustomSelect .select-options-header .button-select-all {
  cursor: pointer;
  color: var(--color-black);
  display: flex;
  flex-direction: row;
}

.CustomSelect .select-container.up .select-options {
  bottom: 0;
  margin-bottom: calc(35px - 1px);
  border-top: 1px solid var(--color-black);
  border-left: 1px solid var(--color-black);
  border-right: 1px solid var(--color-black);
  border-bottom: 1px solid #E0D4D0;
  border-radius: 3px 3px 0 0;
}
.CustomSelect .select-container.show .select-options {
  display: block;
}
.CustomSelect .select-option,
.CustomSelect .select-options-empty {
  margin: 0;
  padding: 8px 15px 8px 15px;
  min-height: 35px;
  cursor: default;
  display: flex;
  flex-direction: row;
}
.CustomSelect .select-option.narrow-padding {
  padding: 7px 15px 7px 15px;
}

.CustomSelect .select-option.selected {
  background: var(--color-light-grey);
}

.CustomSelect .select-option-checkbox {
  margin: 0 10px 0 0;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CustomSelect .select-option-checkbox label {
  margin: 0
}

.CustomSelect .select-option-icon {
  margin: 0 15px 0 0;
  width: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomSelect .select-option-label {
  display: flex;
  flex-direction: column;
}
.CustomSelect .select-option-secondary-label {
  color: var(--color-dark-grey);
  font-size: 0.75em;
  text-align: left;
}

.CustomSelect .select-option-label,
.CustomSelect .icon {
  margin: 0;
}
.CustomSelect .select-option:hover,
.CustomSelect .select-option:focus,
.CustomSelect .select-option.active {
  background: #F7EEFF;
}

.CustomSelect .select-option:hover .CustomCheckbox input ~ .checkmark,
.CustomSelect .select-option:focus .CustomCheckbox input ~ .checkmark {
  border-color: var(--color-black);
}

.CustomSelect .select-option.disabled {
  color: var(--color-dark-grey);
}
.CustomSelect .select-option.disabled:hover,
.CustomSelect .select-option.disabled:focus {
  background: var(--color-very-light-grey) !important;
}

.CustomSelect .select-option.disabled:hover .CustomCheckbox input ~ .checkmark,
.CustomSelect .select-option.disabled:focus .CustomCheckbox input ~ .checkmark {
  border-color: var(--color-medium-grey);
}


.CustomSelect .select-options-empty {
  font-style: italic;
  color: var(--color-dark-grey);
}




.CustomSelect.with-icon .select-input {
  padding-left: 32px;

}
.CustomSelect.with-icon .select-value-container {
  margin: 0 10px 0 35px;
  width: calc(100% - 10px - 35px);
}




.CustomSelect.collapsed .select-container {
  width: 35px !important;
  padding-right: 0;
}
.CustomSelect.collapsed .select-value-container {
  width: 0;
}

.CustomSelect.collapsed .input-icon {
  cursor: pointer;
}
.CustomSelect.collapsed .select-input {
  border: solid 1px var(--color-medium-grey);
  padding-right:  0px;
}

.CustomSelect.collapsed .select-button-container {
  display:  none;
}

.CustomSelect.icon-as-button .select-button-container {
  background: #fff;
}


.graph-controls .CustomSelect {
 
}

.graph-controls .CustomSelect .select-container {
  background: #fff !important; 
  width:  300px; 
  position:  absolute;
  right:  0;
  top:  0;
}

.graph-controls .CustomSelect .select-option,
.graph-controls .CustomSelect .select-options-empty {
  background:  #fff;
}
.graph-controls .CustomSelect .select-option.active,
.graph-controls .CustomSelect .select-option:hover {
  background: #F7EEFF;
}


.graph-controls .CustomSelect .select-container {
  /*border: 0px solid var(--color-medium-grey);*/
}
.graph-controls .CustomSelect:hover .select-container {
  /*border: 0px solid var(--color-black);*/
}



/* 
---------------------------------------------------------------
CustomSelectButtons
--------------------------------------------------------------- 
*/

.CustomSelectButtons label {
  display:  flex;
  flex-direction: row;
}

.CustomSelectButtons.disabled label {
  color: var(--color-dark-grey);
}

.CustomSelectButtons .select-container {
  border: 1px solid var(--color-light-grey);
  border-radius: 3px;
  display: inline-flex;
  height: 35px;
}

.CustomSelectButtons .select-options {
  display: inline-flex;
  background-color: var(--color-very-light-grey);
  border-radius: 3px;
  flex-direction: row;
  padding: 1px;

}
.CustomSelectButtons .select-option:nth-child(n+2) {
  margin-left: 2px;
}
.CustomSelectButtons .select-option {
  margin: 0;
  padding: 0px 10px 0px 10px;
  display: flex;
  min-width: 31px;
  align-items: center;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
}

.CustomSelectButtons .select-option-icon {
  margin: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CustomSelectButtons .select-option-label,
.CustomSelectButtons .icon {
  margin: 0;
}

.CustomSelectButtons .select-option.no-label .select-option-icon {
  margin: 0;
}

.CustomSelectButtons .select-option.no-label {
  padding: 0;
  justify-content: center;
}

.CustomSelectButtons .select-option:hover,
.CustomSelectButtons .select-option:focus {
  background: #F7EEFF;
}

.CustomSelectButtons .select-option:active {
  transform: scale(0.95);
}
.CustomSelectButtons .select-option.active {
  background: var(--color-black);
  color: #fff;
}

.CustomSelectButtons.disabled .select-option,
.CustomSelectButtons .select-option.disabled {
  color: var(--color-dark-grey);
  cursor: default;
}

.CustomSelectButtons.disabled .select-option:hover,
.CustomSelectButtons.disabled .select-option:focus,
.CustomSelectButtons .select-option.disabled:hover,
.CustomSelectButtons .select-option.disabled:focus {
  background: none;
}

.CustomSelectButtons.disabled .select-option:active,
.CustomSelectButtons .select-option.disabled:active {
  transform: none;
}
.CustomSelectButtons.disabled .select-option.active {
  background: var(--color-medium-grey);
  color: #fff;
}

.CustomSelectButtons.bright-design .select-container {
  border-color: var(--color-medium-grey);
}
.CustomSelectButtons.bright-design .select-options {
  background: var(--color-medium-grey) !important;
  padding: 0px;
}
.CustomSelectButtons.bright-design .select-option {
  min-width: 33px;
}

.CustomSelectButtons.bright-design .select-option.active {
  background: #fff;
}

.CustomSelectButtons.bright-design .select-option.active .icon .fill {
  display: block;
}


.CustomSelectButtons.slim-design .select-container {
  border-color: var(--color-medium-grey);
  height: 28px;
}
.CustomSelectButtons.slim-design .select-options {
  background: var(--color-medium-grey) !important;
  padding: 0px;
}
.CustomSelectButtons.slim-design .select-option {
  width: 26px !important;
  min-width: 26px;
  height: 26px !important;
}

.CustomSelectButtons.slim-design .select-option.active {
  background: #fff;
}

.CustomSelectButtons.slim-design .select-option.active .icon .fill {
  display: block;
}


/* 
---------------------------------------------------------------
LineageGraph
--------------------------------------------------------------- 
*/
.LineageGraph .tab-content {
  position: initial !important;
}

.LineageGraph .CustomTabs {
  margin-top: 15px;
}
.LineageGraph .selected-items-popup {
  padding-top: 5px;
}
.LineageGraph .selected-items-popup-section-header {
  margin-right: 25px;
}

.LineageGraph .selected-items-popup-section-title {
  align-items: center;
  justify-content: space-between;
}

.LineageGraph .selected-items-popup-section-title-text {
  display: flex;

}
.LineageGraph .selected-items-popup-section-title-toolbar {
  align-items: center;
  margin-left: 20px;
}

.LineageGraph .selected-items-popup-section-description {
  white-space: wrap;
  word-break: break-all;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
}

.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 0;
  right: 0;
  background: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=');
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

/* 
---------------------------------------------------------------
RelationshipGraph
--------------------------------------------------------------- 
*/
.Graph {
  align-items: flex-start;
  justify-content: flex-start;
  display:  flex;  
  flex-grow: 1;
  width:  100%;
  height:  100%;
}

.Graph.loading {
  align-items: center;
  justify-content: center;
}

.graph-canvas {
  width:  100%;
  height:  100%;
}

.graph-controls {
  position:  absolute;
  top:  0;
  right:  0;
  z-index:  1;
  width:  35px;
}

.graph-controls .graph-control {
  margin-bottom: 5px;

}

.graph-controls .filter-container {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  position:  relative;
  top:  0;
  height:  35px;
}

.graph-legend {
  position:  absolute;
  bottom: 0;
  right: 0;
  font-size:  0.8em;
  color: var(--color-dark-grey);
  display:  flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  height: 100px;
}
.graph-legend-text {
  background:  var(--color-very-light-grey);
  height: 20px;
  display:  flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 5px;
  flex: none;
}
.graph-legend-icon {
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 80px;
}
.graph-legend-circle-min {
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin-top: calc(-26px);
  height: 26px;
  flex: none;
}
.graph-legend-circle-max {
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background:  var(--color-very-light-grey);
}

.graph-legend-value {
  height:  16px;
  text-align: center;
  white-space:  nowrap;
}
.graph-legend-min {
  box-sizing: initial;
  content: "";
  border:  1px solid var(--color-dark-grey);
  border-radius: 100%;
  width:  5px;
  height: 5px;
}
.graph-legend-max {
  box-sizing: initial;
  content: "";
  border:  1px solid var(--color-dark-grey);
  border-radius: 100%;
  width:  40px;
  height: 40px;

}


.Graph .loading-graph {
  display:  flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9em;
  color: var(--color-dark-grey);
}

.Graph .loading-graph-spinner {
  width:  160px;
  height:  135px;
  content: "";
  display: block;
}

.Graph .loading-graph-text {
  margin-top:  20px;
}



/*

.selected-items-popup {
  position:  absolute;
  top:  0;
  left:  0;
  padding:  15px 20px 5px 20px;
  background:  #fff;
  z-index:  2;
  border:  1px solid var(--color-light-grey);
  border-radius: 5px;
  max-height:  100%;
  max-width: 100%;
  display:  flex;
  flex-direction: column;
  overflow:  hidden;
}

.selected-items-popup-header .icon {
  margin-right:  10px;
}
.selected-items-popup-title {
  font-size:  0.9em;
  color:  var(--color-dark-grey);
  margin:  0 25px 10px 0;
}

.selected-items-popup.one-item .selected-items-popup-title {
  display:  none;
  flex-direction: row;
}

.selected-items-popup-content {
  overflow-x:  hidden;
  overflow-y:  auto;
  display:  flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.selected-items-popup-section {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 2;
  justify-content: flex-start;
}

.selected-items-popup.one-item .selected-items-popup-content {

}
.selected-items-popup.one-item .selected-items-popup-section {
  overflow-x:  hidden;
  overflow-y:  auto;
}


.selected-items-popup-section:nth-last-child(n+2) {
  margin:  0 0 30px 0;
}


.selected-items-popup-section-title {
  display:  flex;
  flex-direction:  row;
  align-items: flex-start;
  font-family: "Rubik-Medium";
}
.selected-items-popup-section-title .link {
  font-family: "Rubik-Medium";
}

.selected-items-popup-section-title-text .icon {
  margin:  0 10px 0 0;
}




.selected-items-popup-section-description {
  margin: 10px 0 0 0;
  font-size:  0.9em;
}

.selected-items-popup-section-description.empty {
  margin: 0 0 0 0;
}

.selected-items-popup-section-body {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 2;
  overflow: hidden;
  justify-content: flex-start;
}
.selected-items-popup-section-footer {
  margin:  5px 0 0 0;  
}
.toggle-all-fields {
  font-size:  0.8em;
  cursor: pointer; 
  display:  inline-block;
  margin:  0 0 0 35px;
  color: var(--color-dark-grey);
}
.toggle-all-fields:hover {
  color:  var(--color-black); 
}

.selected-items-popup .DataTableWrapper {
  width: 100% !important;
}

.selected-items-popup .DataTable {
  position: initial;
}

.selected-items-popup .DataTable .row {
  font-size:  0.9em;
}
.selected-items-popup .DataTable .row:hover {
  background-color:  #F7EEFF;
}

.selected-items-popup .DataTable td {
  padding:  5px;
}
.selected-items-popup .DataTable th {
  padding:  0px 5px 5px 5px;
  padding-bottom:  5px;
  background-color:  #fff;
}
.selected-items-popup .DataTable .type-icon {
  width:  30px;
}
.selected-items-popup .empty-text {
  margin: 10px;
  font-size:  0.9em;
}

.toggle-popup {
  position:  absolute;
  top:  10px;
  right: 10px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transform: rotate(45deg);
  cursor: pointer;
}
.toggle-popup::after {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 5px;
  top: 10px;
  display: block;
}
.toggle-popup::before {
  width: 15px;
  height: 2px;
  content: '';
  border-radius: 1px;
  background: var(--color-dark-grey);
  position: relative;
  left: 5px;
  top: 12px;
  display: block;
  transform: rotate(90deg);
}
.toggle-popup:hover {
  background: var(--color-black);
}
.toggle-popup:hover::before, 
.toggle-popup:hover::after {
  background: #fff;
}

.selected-items-popup.hide {
transition: background-color 0.1s ease-out, border-color 0.1s ease-out;
}

.selected-items-popup.hide .selected-items-popup-content
 {
  display:  none;
}
.selected-items-popup.hide .selected-items-popup-title {
  color:  var(--color-black);
  font-family: "Rubik-Medium";
}

.selected-items-popup.hide:hover {
  border:  1px solid var(--color-black);
  cursor: pointer;
  background:  var(--color-black);
}
.selected-items-popup.hide:hover .selected-items-popup-title {
  color:  #fff;
}


.selected-items-popup.hide.one-item .selected-items-popup-title {
  display:  flex;
  font-size: 1em;
}
.selected-items-popup.hide .toggle-popup {
  top:  12px;
  transform: rotate(45deg);
}
.selected-items-popup.hide .toggle-popup::after {
  top:  5px;
  left:  4px;
  width:  13px;
}
.selected-items-popup.hide .toggle-popup::before {
  top:  13px;
  left:  10px;
  width:  13px;
}

.selected-items-popup.hide:hover .toggle-popup {
  background: transparent;
}
.selected-items-popup.hide:hover .toggle-popup::after {
  background: #fff;
}
.selected-items-popup.hide:hover .toggle-popup::before {
  background: #fff;
}*/


/* 
---------------------------------------------------------------
CustomTooltip
--------------------------------------------------------------- 
*/

.CustomTooltip {
  position:  absolute;
  display:  flex;
  /*visibility: hidden;*/
  flex-direction: column;
  background:  #fff;
  /*border:  1px solid var(--color-dark-grey)66;*/
  border-radius: 3px;
  box-shadow: 1px 1px 2px 1px rgba(35,31,32,0.2);
  padding:  8px;
  z-index:  100;
  max-width: 500px;
  word-break: break-word;
  font-size:  0.9em;

}

/*.CustomTooltip.show {
  display:  flex;
  visibility: visible;
}*/

.CustomTooltip .graph-tooltip-container {
  display:  flex;
  flex-direction: column;
}

.CustomTooltip .graph-tooltip {
  display:  flex;
  flex-direction: row;
}

.CustomTooltip .graph-tooltip-footer {
  color: var(--color-dark-grey);
  text-align: right;
  font-size:  0.9em;
  margin: 10px 0 0 0;
} 

.CustomTooltip .graph-tooltip-edge-direction {
  padding:  28px 7px 0px 14px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CustomTooltip .lineage-graph .graph-tooltip-edge-direction {
  padding:  2px 7px 0px 14px;
}

.CustomTooltip .graph-tooltip-edge-direction div {
  /*padding:  28px 0 0 0;*/
  min-height: 18px;

}

.CustomTooltip .first-row {
 font-family: "Rubik-Regular";
 display:  flex;
 flex-direction:  row;
 align-items: center;
 min-height: 18px;

}
.CustomTooltip .second-row { 
  margin: 10px 0 0 0;
  display:  flex;
  flex-direction:  row;
  height: 18px;
}

.CustomTooltip .lineage-graph .second-row {
  margin: 5px 0 0 0;
}

.CustomTooltip .tooltip-subtitle {
  margin: 5px 0 0 0;
  color:  var(--color-dark-grey);
  font-size:  0.9em;
  display:  flex;
  flex-direction:  row;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-wrap;
  tab-size: 50px;
}
.CustomTooltip .inverted-margin {
  margin: 0 0 5px 0 !important;
}
.CustomTooltip .tooltip-title { 
  font-family: "Rubik-Regular";
  font-size:  1.0em;
  display:  flex;
  flex-direction:  row;
  justify-content: flex-start;
  align-items: center;
}
.CustomTooltip .tooltip-title.thin { 
  font-family: "Rubik-Light";
}

.CustomTooltip .icon {
  margin:  0 7px 0 0;
}

.CustomTooltip .tooltip-pretitle { 
  text-transform: uppercase;
  margin:  0 0 5px 0;
  font-size:  0.6em;
  font-family: "Rubik-Regular";
}

.CustomTooltip .tooltip-body { 
  display:  flex;
  margin: 5px 0 0 0;
  flex-direction:  row;
  justify-content: flex-start;
  align-items: center;
  white-space: pre-wrap;
}

.CustomTooltip.no-title .tooltip-body { 
  margin: 0 0 0 0;
}
.CustomTooltip.no-title { 
  padding: 4px 8px;
}

/* 
---------------------------------------------------------------
CustomSlider
--------------------------------------------------------------- 
*/
.CustomSlider {
  display:  flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--color-very-light-grey);
  border-radius:  3px;
}

.CustomSlider.vertical .input-container {
  width:  150px;
  height:  20px;
  margin: 75px 0;
  transform:  rotate(-90deg);
  order:  2;
}

/*.CustomSlider.vertical .input-container::after {*/
.CustomSlider.vertical .slider-center-tick {
  width:  2px;
  height:  9px;
  background:  var(--color-medium-grey);
  content:  '';
  position:  absolute;
  top:  6px;
  left:  75px;
  z-index: 1;
}

/* Remove base styles */
.CustomSlider input[type=range] {
  -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
  width: 100%; /* Specific width is required for Firefox. */
  background: transparent; /* Otherwise white in Chrome */
  z-index: 2;
  position:  absolute;
  
}

.CustomSlider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

.CustomSlider input[type=range]:focus {
  outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
}

.CustomSlider input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

/* Apply styles to slider */
.CustomSlider input[type=range]::-webkit-slider-runnable-track {
  /*width: 100%;*/
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
  box-shadow: none;
  margin-top: 7px
}


.CustomSlider input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--color-medium-grey);
}

.CustomSlider input[type=range]::-moz-range-track {
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
}

.CustomSlider input[type=range]::-ms-track {
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
}
.CustomSlider input[type=range]::-ms-fill-lower {
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
}
.CustomSlider input[type=range]:focus::-ms-fill-lower {
  background: var(--color-medium-grey);
}
.CustomSlider input[type=range]::-ms-fill-upper {
  width: 100%;
  height:  3px;
  cursor: pointer;
  background: var(--color-medium-grey);
  border-radius: 3px;
}
.CustomSlider input[type=range]:focus::-ms-fill-upper {
  background: var(--color-medium-grey);
}

/* Apply styles to thumb */
.CustomSlider input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid var(--color-dark-grey);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
  margin-top: -6px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* All the same stuff for Firefox */
.CustomSlider input[type=range]::-moz-range-thumb {
  border: 1px solid var(--color-dark-grey);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
}

/* All the same stuff for IE */
.CustomSlider input[type=range]::-ms-thumb {
  border: 1px solid var(--color-dark-grey);
  height: 15px;
  width: 15px;
  border-radius: 100%;
  background: #ffffff;
  cursor: pointer;
}

.CustomSlider input[type=range]::-moz-range-thumb:hover,
.CustomSlider input[type=range]::-moz-range-thumb:active {
  border: 1px solid var(--color-black);
}

.CustomSlider input[type=range]::-webkit-slider-thumb:hover,
.CustomSlider input[type=range]::-webkit-slider-thumb:active {
  border: 1px solid var(--color-black);
}

.CustomSlider input[type=range]::-ms-thumb:hover,
.CustomSlider input[type=range]::-ms-thumb:active {
  border: 1px solid var(--color-black);
}



.CustomSlider.horizontal .max-label {
  order:  3;
  padding-left:  5px;
  
}
.CustomSlider.horizontal .min-label {
  order:  1;
  padding-right:  5px;
  
}
.CustomSlider .max-label {
  order:  1;
  font-family:  "Rubik-Regular";
}
.CustomSlider .min-label {
  order:  3;
  font-family:  "Rubik-Regular";
}
.CustomSlider.vertical {
  flex-direction: column;
  
}

/* 
---------------------------------------------------------------
ADSearchResult
--------------------------------------------------------------- 
*/

.side-dialog-search-input {
  position: relative;
  margin-bottom: 15px;
}
.side-dialog-search-loading-indicator {
  position: absolute;
  bottom: -22px;
  margin-left: 10px;
  color: var(--color-dark-grey);
  font-size: 0.9em;
}
.side-dialog-search-result-count {
  position: absolute;
  bottom: -22px;
  right: 0;
  margin-right: 10px;
  color: var(--color-dark-grey);
  font-size: 0.9em;
}

.side-dialog-search-result {
  display: flex;
  flex-direction: row;
  padding: 10px;
  align-items: center;
  border-radius: 3px;
  position: relative;
}

.side-dialog-search-result:active {
  transform: scale(0.98);
}

.side-dialog-search-result.selected {
  color: var(--color-dark-grey);
}

.side-dialog-search-result.disabled:active {
  transform:  none;
} 
.side-dialog-search-result.selected:active {
  transform: none;
}

.side-dialog-search-result-photo {
  display:  flex;
  justify-content: center;
  margin-right: 10px;
}

.side-dialog-search-result-icon {
  display:  flex;
  justify-content: flex-start;
  align-items: flex-start;
  align-self: flex-start;
  margin-right: 10px;
}
.side-dialog-search-result-photo .photo-img {
  width: 32px;
  height: 32px;
  min-width: 32px;
  border-radius: 50%;
}
.side-dialog-search-result-photo .photo-img.placeholder {
  border-radius: 0%;
}
.side-dialog-search-result.selected .ad-search-result-photo {
  opacity: 0.2;
}
.side-dialog-search-result-body {
  display: flex;
  flex-direction: column;
}
.side-dialog-search-result-title {
  width: 100%;
  display:  flex;
  flex-direction: row;
  justify-content: flex-start;
}
.side-dialog-search-result-subtitle {
  font-size: 0.9em;
  color: var(--color-dark-grey);
  width: 100%;
}
.side-dialog-search-result-selected {
  position: absolute;
  right: 10px;
  top:  0px;
  height:  100%;
  display:  flex;
  align-items: center;
  font-size: 0.9em;
  font-style: italic;
  background: #fff;
  padding: 0 0 0 10px;
}

.side-dialog-search-result:hover {
  background: #F7EEFF;
  cursor: pointer;
}
.side-dialog-search-result.disabled:hover {
  background: initial;
  cursor: default;
} 
.side-dialog-search-result.selected:hover {
  background: initial;
  cursor: default;
}

/* 
---------------------------------------------------------------
Photo
--------------------------------------------------------------- 
*/
.Photo .photo-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

.Photo.small,
.Photo.small .photo-img,
.Photo.small .photo-loader {
  width: 32px;
  height: 32px;
}

.Photo.small.placeholder .photo-img,
.Photo.small.placeholder .photo-loader {
  width: 28px;
  height: 28px;
}
.Photo.small.placeholder {
  width: 32px;
  height: 32px;
  padding: 2px;
  display: flex;
  align-items: center;
}

.Photo.medium,
.Photo.medium .photo-img,
.Photo.medium .photo-loader {
  width: 80px;
  height: 80px;
}

.Photo.large,
.Photo.large .photo-img,
.Photo.large .photo-loader {
  width: 120px;
  height: 120px;
}

.Photo.placeholder .photo-img {
  border-radius: 0%;
}

/* 
---------------------------------------------------------------
UserLink
--------------------------------------------------------------- 
*/
.UserLink {
  display:  inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

}
.UserLink.with-header {
  margin-top: 20px;
}
.UserLink .user-link-body {
  display:  inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

}

.UserLink .user-link-name {
  line-break: auto;
}

.UserLink .user-link-body.width-160 .user-link {
  width: 160px;
}

.UserLink .user-link-body.photo-background .Photo.placeholder.small {
  background: var(--color-very-light-grey);
  padding: 5px 2px;
  height: 42px;
}
.UserLink .user-link-body.photo-background .Photo.small {
  background: var(--color-very-light-grey);
  padding: 5px 0;
  height: 42px;
}


.UserLink.photo-right .user-link-body {
  flex-direction: row-reverse;
}

.UserLink .Photo {
  margin: 0 10px 0 0;
  max-width: 32px;
}

.UserLink.photo-right .Photo {
  margin: 0 0 0 10px;
}

.UserLink .user-link-header {
  font-size: 0.9em;
  height: 20px;
  margin-top: -20px;
  color: var(--color-dark-grey);
}



/* 
---------------------------------------------------------------
Skeleton
--------------------------------------------------------------- 
*/
.react-loading-skeleton {
  border-radius: 100px !important;
  background-color: rgba(35,31,32,0.05) !important;
}

.react-loading-skeleton::after {
  background-image: linear-gradient(
    90deg,
    rgba(255,255,255,0),
    rgba(255,255,255,1),
    rgba(255,255,255,0)
  ) !important;
}