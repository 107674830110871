/* 
---------------------------------------------------------------
CustomTextarea
--------------------------------------------------------------- 
*/
.CustomTextarea .public-DraftEditor-content {
  border: solid 1px var(--color-light-grey);
  background: var(--color-very-light-grey);
  padding: 8px 15px 0px 15px;
  width: 100%;
  min-height: calc(10vh - 35px);
  max-height: calc(30vh - 35px);
  overflow-y: auto;
  line-height: 1.3em;
  border-radius: 3px 3px 0 0;
  border-bottom: none !important;
}

.CustomTextareaCode .public-DraftEditor-content {
  border: solid 1px var(--color-light-grey);
  border-bottom: solid 1px var(--color-light-grey);
  background: var(--color-very-light-grey);
  padding: 8px 15px 20px 15px !important;
  overflow-y: auto;
  min-height: calc(40vh);
  max-height: calc(80vh - 120px);
  min-width: 40vw;
  width: 100%;
  line-height: 1.5em !important;
  border-radius: 3px;
}

.column .CustomTextareaCode .public-DraftEditor-content {
  margin-top: 10px;
  min-height: calc(100vh - 160px);
  background: #fff;
}

.CustomTextareaCode.read-only .public-DraftEditor-content {
  
}


.CustomTextareaCode {
  font-family: "JetBrainsMono", monospace;
  font-size: 0.85em;
  transform: translate3d( 0, 0, 0); /* Fix for some obscure anti-aliasing bug that occurs on the textarea in some circumstances */
}

.CustomTextareaCode .public-DraftEditorPlaceholder-root {
  position: relative;
}
.CustomTextareaCode .public-DraftEditorPlaceholder-inner {
  color: var(--color-dark-grey);
  position: absolute;
  margin-left: calc(6ch + 1px + 5px + 5px);
  margin-top: 9px;
  top: 0;
  left: 0;
}

.CustomTextarea .public-DraftEditorPlaceholder-root {
  position: relative;
}
.CustomTextarea .public-DraftEditorPlaceholder-inner {
  color: var(--color-dark-grey);
  position: absolute;
  margin-left: 15px;
  margin-top: 10px;
  top: 0;
  left: 0;
}

.CustomTextarea .public-DraftEditor-content,
.CustomTextareaReadOnly .public-DraftEditor-content {
  line-height: 1.3em;
  overflow-wrap: normal !important;
}

.CustomTextarea .public-DraftStyleDefault-block,
.CustomTextareaReadOnly .public-DraftStyleDefault-block {
  margin-bottom: 10px;
}

.CustomTextareaCode .public-DraftStyleDefault-block {
  margin-bottom: 0;
}

.CustomTextarea:hover .public-DraftEditor-content,
.CustomTextarea:hover .format-menu,
.CustomTextarea.has-focus .format-menu  {
  border-color: var(--color-black);
}
.CustomTextarea.disabled .public-DraftEditor-content {
  color: var(--color-dark-grey);
}
.CustomTextarea.disabled .public-DraftEditor-content li {
  color: var(--color-dark-grey);
}
.CustomTextarea.disabled .public-DraftEditor-content:hover {
  border: solid 1px var(--color-light-grey);
}
.CustomTextarea.disabled .textarea-reference:hover {
  color: var(--color-dark-grey);
  cursor: text;
}

.CustomTextarea.has-focus .format-menu-content {
  display: flex;
}

.CustomTextarea .format-menu-button:nth-child(n+1) {
  margin-right: 5px;
}

.CustomTextarea .public-DraftEditor-content:focus {
  border: solid 1px var(--color-black);
}
.CustomTextarea .public-DraftEditor-content:disabled {
  color: var(--color-dark-grey);
}
.CustomTextarea .public-DraftEditor-content:disabled:hover {
  border: solid 1px var(--color-light-grey);
}
.CustomTextarea.disabled:hover .public-DraftEditor-content,
.CustomTextarea.disabled:hover .format-menu {
  border-color: var(--color-light-grey);
}

.CustomTextarea.disabled label {
  color: var(--color-dark-grey);
}

.CustomTextarea .format-menu {
  height: 35px;
  flex-direction: row;
  align-items: center;
  display: flex;
  padding: 0 10px 0 10px;
  background: var(--color-very-light-grey);
  border-radius: 0 0 3px 3px;
  border: solid 1px var(--color-light-grey);
  border-top: none;
}

.CustomTextarea .format-menu-content {
  height: 35px;
  display: none;
  flex-direction: row;
  align-items: center;
  border-top: solid 1px var(--color-medium-grey)88;
  width: 100%;
}

.CustomTextarea .format-menu-button-group {
  margin-right: 20px;
  display: flex;
  flex-direction: row;
}

.CustomTextarea .format-menu-button {
  border-radius: 3px; 
  height: 26px;
  width: 26px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.CustomTextarea .format-menu-button:hover,
.CustomTextarea .format-menu-button.active {
  background: var(--color-black);
  color: #fff;
}

.CustomTextarea .format-menu-button:hover .icon-editor path,
.CustomTextarea .format-menu-button.active .icon-editor path {
  fill: #fff !important;
}

.CustomTextarea .textarea-reference,
.CustomTextareaReadOnly .textarea-reference {
  font-family: "Rubik-Regular";
  border-bottom: 1px dotted var(--color-medium-grey) !important;
  cursor: pointer;
}
.CustomTextarea .textarea-reference:hover,
.CustomTextareaReadOnly .textarea-reference:hover {
  color: #E4C3FF;
}

.CustomTextarea .format-menu-button .bold {
  font-family: 'Rubik-Medium';
}

.CustomTextarea .format-menu-button .italic {
  font-family: "Courier New", Courier, monospace;
  font-style: italic;
}

.CustomTextarea .format-menu-button .underline {
  text-decoration: underline;
}

.CustomTextarea ul,
.CustomTextarea ol,
.CustomTextareaReadOnly ul,
.CustomTextareaReadOnly ol {
  margin-left: 15px;
  margin-bottom: 10px !important;
}
.CustomTextarea li,
.CustomTextareaReadOnly li {
  margin-bottom: 5px !important;
}

.CustomTextarea li div,
.CustomTextareaReadOnly li div {
  margin-bottom: 0px !important;
}


.CustomTextareaSuggestions {
  background: #fff;
  border: 0px solid var(--color-medium-grey);
  display: inline-block;
  position: absolute;
  z-index: 1000;
  border-radius: 3px;
  box-shadow: 0px 3px 3px rgba(35,31,32,0.2);
  white-space: nowrap;
}

.CustomTextareaSuggestions .content {
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.CustomTextareaSuggestions .option {
  cursor: pointer;
  /*display: flex;*/
  align-items: center;
}

.CustomTextareaSuggestions table {
  border-collapse: collapse;
  
}

.CustomTextareaSuggestions .no-results {
  padding: 4px 30px 4px 10px;
  font-size: 0.8em;
  color: var(--color-dark-grey);
}

.CustomTextareaSuggestions .option:hover, 
.CustomTextareaSuggestions .option.selected {
  background: #F7EEFF;
}

.CustomTextareaSuggestions .object-type {
  padding: 2px 10px 2px 10px;
  color: var(--color-dark-grey);
  font-size: 0.8em;
  font-family: "Rubik-Regular";
  text-align: left;

  
}

.CustomTextareaSuggestions .object-name {
  padding: 2px 10px 2px 10px;
  width:  100%;

}

.CustomTextareaSuggestions .object-name .bold {
  font-family: "Rubik-Regular";

}

.CustomTextareaSuggestions .footer {
  position: static;
  bottom: 0;
  color: var(--color-dark-grey);
  font-size: 0.8em;
  padding: 4px 10px;
}

.CustomTextareaCode .line {
  position: relative;
  white-space: pre;
  display: inline-block;
}

.CustomTextareaCode .line::selection {
  background: #000000 !important;
}

.CustomTextareaCode.show-line-numbers .line:before {
  content: attr(data-line-number);
  position: absolute;
  left: 0;
  top: 0;
  width: 4ch;
  text-align: right;
  color: var(--color-dark-grey);
}

.CustomTextareaCode .line-text {
  margin-left: 5px;
  margin-right: 15px;
  tab-size: 4;
}

.CustomTextareaCode.show-line-numbers .line-text {
  margin-left: calc(6ch + 5px);
}

.TextareaAutosize label {
  display: flex;
}
.TextareaAutosize textarea {
  padding: 6px 15px;
  min-height: initial;
  line-height: 1.3;
}